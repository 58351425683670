/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import {Layout, theme} from "antd";
import {Outlet} from "react-router-dom";
import GlobalFooter from "../../common/components/footer/Footer";
import "./GlobalLayout.css";
import Navbar from "../../common/components/navbar/Navbar";
import User from "../../common/components/navbar/user/User";

const { Header, Content, Footer } = Layout;

const item = () => {
  return <div className="search">Search Bar</div>;
};

const menus = [
  {
    key: "home",
    label: "HOME",
  },
  {
    key: "search",
    label: item(),
  },
  {
    key: "user",
    label: <User />,
  },
];

const Structure = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <div>
      <Layout>
        <Navbar />
        {/*<div className="search-area">*/}
        {/*  <NavSearchBar />*/}
        {/*</div>*/}
        <Content>
          <div className="global-layout-container">
            <Outlet />
          </div>
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          <GlobalFooter />
        </Footer>
      </Layout>
    </div>
  );
};

export default Structure;
