/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useState, useRef } from "react";
import { Form, Input, Select, Typography, Layout } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import "./Step.css";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { googleMapsConfig } from "../../../../common/configs/googleMap";
import { mapStyles } from "../../../../common/configs/mapStyles";

const { Option } = Select;
const { Content } = Layout;

const InitialStep = ({ form, formData, handleChange }) => {
  const { isLoaded } = useJsApiLoader({
    id: googleMapsConfig.id,
    googleMapsApiKey: googleMapsConfig.googleMapsApiKey,
    libraries: googleMapsConfig.libraries,
  });

  const mapContainerStyle = {
    width: "100%",
    height: "100%",
  };
  const [location, setLocation] = useState({ lat: 6.927079, lng: 79.861244 });

  const [markerRef, setMarkerRef] = useState();
  const inputRef = useRef(null);
  const handleMapClick = (e) => {
    const { latLng } = e;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setMarkerRef({ lat: lat, lng: lng });
    console.log(markerRef);
    // Update the state with the clicked coordinate
  };

  const handlePlaceChanged = () => {
    if (inputRef.current) {
      const place = inputRef.current.getPlaces()[0];
      const selectedAddress = place.formatted_address || place.name;
      const lat = place.geometry?.location?.lat();
      const lng = place.geometry?.location?.lng(); // Use formatted_address if available
      setLocation({
        lat: lat,
        lng: lng,
      });
    }
  };

  return (
    <div className="initial-step-form">
      {/*<Form layout="vertical"*/}
      {/*      initialValues={formData}*/}
      {/*      form={form}*/}

      {/*>*/}
      <Typography.Title level={3}>Store Location</Typography.Title>
      {/* Location Search */}
      <Form.Item>
        {isLoaded && (
          <StandaloneSearchBox
            onLoad={(ref) => (inputRef.current = ref)}
            onPlacesChanged={handlePlaceChanged}
          >
            <Form.Item name="location" label="Location">
              <Input placeholder="Enter location" style={{ borderRadius: 8 }} />
            </Form.Item>
          </StandaloneSearchBox>
        )}
      </Form.Item>

      {/* Google Maps Image Placeholder */}
      {/* <div
        style={{
          width: "100%",
          height: "200px",
          backgroundImage:
            "url(https://maps.gstatic.com/tactile/basepage/pegman_sherlock.png)",
          backgroundSize: "cover",
          borderRadius: 8,
          marginBottom: 24,
        }}
      /> */}

      <Content
        // className={`map-container map-view`}
        style={{ width: "100%", height: "200px" }}
      >
        {/*<LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>*/}
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={location}
            zoom={9}
            options={{
              styles: mapStyles, // Apply custom styles here
              disableDefaultUI: true, // Disables default UI like zoom controls, map type selector
            }}
            onClick={handleMapClick}
          >
            {markerRef && <Marker position={markerRef} />}
          </GoogleMap>
        )}
        {/*</LoadScript>*/}
      </Content>

      {/* Address Fields */}
      <Form.Item name="address1" label="Address 1">
        <Input placeholder="Address 1" style={{ borderRadius: 8 }} />
      </Form.Item>

      <Form.Item name="address2" label="Address 2">
        <Input placeholder="Address 2" style={{ borderRadius: 8 }} />
      </Form.Item>

      <Form.Item name="postalCode" label="Postal Code">
        <Input placeholder="Postal Code" style={{ borderRadius: 8 }} />
      </Form.Item>

      {/* Country Selector */}
      <Form.Item name="country" label="Country">
        <Select placeholder="Select a country" style={{ borderRadius: 8 }}>
          <Option value="usa">United States</Option>
          <Option value="canada">Canada</Option>
          <Option value="mexico">Mexico</Option>
          {/* Add more countries as needed */}
        </Select>
      </Form.Item>

      {/* Description */}
      <Form.Item name="description" label="Description">
        <TextArea
          rows={4}
          placeholder="Additional details of the located store for direction"
          style={{ borderRadius: 8 }}
        />
      </Form.Item>
      {/*</Form>*/}
    </div>
  );
};

export default InitialStep;
