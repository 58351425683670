/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useState } from "react";
import { Space, Tag } from "antd";
import SearchComponent from "../../../common/search/Search";
import TableAntd from "../../../common/components/table/TableAntd";

const PartnerTransaction = () => {
  const [current, setCurrent] = useState(1);

  const columns = [
    {
      title: "Store Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Month",
      dataIndex: "month",
      key: "month",
    },
    {
      title: "Status",
      key: "tags",
      dataIndex: "tags",
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            let color = tag.length > 9 ? "geekblue" : "green";
            if (tag === "canceled") {
              color = "volcano";
            }
            console.log(tag);
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Booked Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a>Invite {record.name}</a>
          <a>Delete</a>
        </Space>
      ),
    },
  ];

  // const data = [
  //   {
  //     key: '1',
  //     name: 'John Brown',
  //     age: 32,
  //     address: 'New York No. 1 Lake Park',
  //     tags: ['nice', 'developer'],
  //   },
  //   {
  //     key: '2',
  //     name: 'Jim Green',
  //     age: 42,
  //     address: 'London No. 1 Lake Park',
  //     tags: ['loser'],
  //   },
  //   {
  //     key: '3',
  //     name: 'Joe Black',
  //     age: 32,
  //     address: 'Sydney No. 1 Lake Park',
  //     tags: ['cool', 'teacher'],
  //   },
  //   {
  //     key: '5',
  //     name: 'Jim Green',
  //     age: 42,
  //     address: 'London No. 1 Lake Park',
  //     tags: ['loser'],
  //   },
  //   {
  //     key: '6',
  //     name: 'Joe Black',
  //     age: 32,
  //     address: 'Sydney No. 1 Lake Park',
  //     tags: ['cool', 'teacher'],
  //   },
  //   {
  //     key: '7',
  //     name: 'Jim Green',
  //     age: 42,
  //     address: 'London No. 1 Lake Park',
  //     tags: ['loser'],
  //   },
  //   {
  //     key: '8',
  //     name: 'Joe Black',
  //     age: 32,
  //     address: 'Sydney No. 1 Lake Park',
  //     tags: ['cool', 'teacher'],
  //   },
  //   {
  //     key: '9',
  //     name: 'Jim Green',
  //     age: 42,
  //     address: 'London No. 1 Lake Park',
  //     tags: ['loser'],
  //   },
  //   {
  //     key: '10',
  //     name: 'Joe Black',
  //     age: 32,
  //     address: 'Sydney No. 1 Lake Park',
  //     tags: ['cool', 'teacher'],
  //   },
  //   {
  //     key: '11',
  //     name: 'Joe Black',
  //     age: 32,
  //     address: 'Sydney No. 1 Lake Park',
  //     tags: ['cool', 'teacher'],
  //   },
  // ];

  const generateMockData = (count = 50) => {
    const names = [
      "John Brown",
      "Jim Green",
      "Joe Black",
      "Jane Doe",
      "Emily White",
    ];
    const addresses = [
      "New York No. 1 Lake Park",
      "London No. 1 Lake Park",
      "Sydney No. 1 Lake Park",
      "Tokyo No. 1 Lake Park",
      "Paris No. 1 Lake Park",
    ];
    const tagOptions = [
      ["completed"],
      ["processing"],
      ["canceled"],
      ["completed"],
      ["processing"],
    ];

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const bookingDate = [
      "2024-01-12",
      "2024-06-19",
      "2024-06-15",
      "2024-07-15",
    ];

    return Array.from({ length: count }).map((_, i) => ({
      key: (i + 1).toString(),
      name: "Store " + names[i % names.length] + " " + i,
      month: months[i % months.length],
      address: addresses[i % addresses.length],
      date: bookingDate[i % bookingDate.length],
      tags: tagOptions[i % tagOptions.length],
      amount:
        "£" +
        ((30 + i / 15) * 156)
          .toFixed(3)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    }));
  };

  const data = generateMockData();

  const handleTableChange = (pagination, filters, sorter) => {
    // Update the current page state
    setCurrent(pagination.current);

    console.log("Pagination:", pagination);
    console.log("Filters:", filters);
    console.log("Sorter:", sorter);

    // You can add additional logic here, such as fetching new data based on pagination
  };

  const searchFilters = [
    { key: "name", label: "Store Name" },
    { key: "date", label: "Date" },
    { key: "status", label: "Status" },
  ];

  const handleSearch = (values) => {
    console.log("Search Values:", values); // Log search values or perform search logic here
  };

  return (
    <div>
      <div className="booking-container">
        <div>
          <SearchComponent filters={searchFilters} onSearch={handleSearch} />
        </div>
        <div className="booking-table">
          <TableAntd
            columns={columns}
            data={data}
            total={data.length}
            handleTableChange={handleTableChange}
          />
        </div>
      </div>
    </div>
  );
};

export default PartnerTransaction;
