/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import ContactUsCard from "../ContactUsCard/ContactUsCard";
import "./ContactUsHeader.css";


const ContactUsHeader = () => {
  return (
    <div>
      <div className="contact-us">
        <div className="contact-us-top-header">
          <h1>Contact Our Friendly Team</h1>
          <p>We’re here to help. Choose an option below:</p>
        </div>
        <div className="contact-cards">
          <ContactUsCard
            title="Contact Sales"
            description="Get in touch with our sales team for any inquiries."
            icon="Chat to Sales"
            contact="sales@cryptoluggagestorage.com"
          />
          <ContactUsCard
            title="Contact Support"
            description="Need help? Our support team is here for you."
            icon="Chat to Support"
            contact="support@cryptoluggagestorage.com"
          />
          <ContactUsCard
            title="Visit Us"
            description="Come see us in person at our office location."
            icon="Visit Us"
          />
          <ContactUsCard
            title="Call Us"
            description="Speak directly with one of our representatives."
            icon="Call Us"
          />
        </div>
      </div>
    </div>
  );
};

export default ContactUsHeader;