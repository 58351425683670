/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Button, Col, Row, Typography} from "antd";

const {Title, Text} = Typography;

const UserModal = ({userInfo, handleConfirm, handleBlock}) => {
  return (
    <div>
      <div style={{ padding: "20px"}}>
        <Title
          level={3}
          style={{
            textAlign: "center",
            marginBottom: "20px",
            borderBottom: "1px solid #000",
          }}
        >
          User Details
        </Title>

        <Row style={{textAlign: "start", lineHeight: "5px"}} gutter={[16, 8]}>
          <Col span={6}>
            <Text strong>User Name:</Text>
          </Col>
          <Col span={16}>
            <Text>{userInfo.name}</Text>
          </Col>
          <Col span={6}>
            <Text strong>Email:</Text>
          </Col>
          <Col span={14}>
            <Text>{userInfo.email}</Text>
          </Col>
          <Col span={6}>
            <Text strong>Mobile:</Text>
          </Col>
          <Col span={14}>
            <Text>{userInfo.telephone}</Text>
          </Col>
          <Col span={6}>
            <Text strong>User Type:</Text>
          </Col>
          <Col span={14}>
            <Text>{userInfo.type}</Text>
          </Col>

          <Col span={6}>
            <Text strong>Status</Text>
          </Col>
          <Col span={8}>
            <Text>{userInfo.status.toString().toUpperCase()}</Text>
          </Col>
          {/* Conditional rendering based on the status */}
          {userInfo.status.toLocaleString() === 'ACTIVE' ? (

            <Button
              className="blocked-btn"
              onClick={handleBlock}
            >
              Block
            </Button>
          ) : (
            <Button
              className="confirm-btn"
              onClick={handleConfirm}
            >
              ACTIVATE
            </Button>
          )}
        </Row>

      </div>
    </div>
  );
};

export default UserModal;