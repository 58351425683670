/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useState} from 'react';
import {Button, Form, Input, Modal} from 'antd';

const CreateUser = ({isCreateUserModalVisible, setIsCreateUserModalVisible, handleCancel}) => {
  // Modal visibility state
  const [form] = Form.useForm(); // Ant Design form hook

  // Handle form submission
  const handleSubmit = (values) => {
    console.log('New User Data: ', values);
    // Here you can add logic to send the data to an API or handle it accordingly
    handleCancel();
  };

  return (
    <>
      <Modal
        title="Add New User"
        open={isCreateUserModalVisible}
        onCancel={(handleCancel)}
        footer={null} // Disable default footer
        width={600} // You can customize the modal width
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit} // Trigger form submission
        >
          {/* User Name Input */}
          <Form.Item
            label="Full Name"
            name="name"
            rules={[{required: true, message: 'Please enter the name!'}]}
          >
            <div>
              <div style={{marginBottom: '8px', color: '#8c8c8c'}}>Provide the full name for the user</div>
              <Input placeholder="Enter full name"/>
            </div>
          </Form.Item>

          {/* Email Input */}
          <Form.Item
            label="Email"
            name="email"
            rules={[{required: true, type: 'email', message: 'Please enter a valid email!'}]}
          >
            <div>
              <div style={{marginBottom: '8px', color: '#8c8c8c'}}>Provide a valid email address</div>
              <Input placeholder="Enter email"/>
            </div>
          </Form.Item>

          {/* Phone Number Input */}
          <Form.Item
            label="Phone Number"
            name="phone"
            rules={[{required: true, message: 'Please enter the phone number!'}]}
          >
            <div>
              <div style={{marginBottom: '8px', color: '#8c8c8c'}}>Provide a valid mobile number</div>
              <Input placeholder="Enter phone number"/>
            </div>
          </Form.Item>

          {/* Password Field */}
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {required: true, message: 'Please input the password!'},
              {min: 6, message: 'Password must be at least 6 characters!'}
            ]}
            hasFeedback
          >
            <div>
              <div style={{marginBottom: '8px', color: '#8c8c8c'}}>Set a strong password</div>
              <Input.Password placeholder="Enter password"/>
            </div>
          </Form.Item>

          {/* Confirm Password Field */}
          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            dependencies={['password']}
            rules={[
              {required: true, message: 'Please confirm your password!'},
              ({getFieldValue}) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords do not match!'));
                },
              }),
            ]}
            hasFeedback
          >
            <div>
              <div style={{marginBottom: '8px', color: '#8c8c8c'}}>Confirm your password</div>
              <Input.Password placeholder="Confirm password"/>
            </div>
          </Form.Item>

          {/* Submit Button */}
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateUser;