/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useState } from "react";
import {
  Card,
  Descriptions,
  Form,
  Input,
  Modal,
  Skeleton,
  Space,
  Tag,
} from "antd";
import SearchComponent from "../../../common/search/Search";
import TableAntd from "../../../common/components/table/TableAntd";
import {
  getStorageById,
  getStorageByUserId,
} from "../../../common/api/storage";
import { getBookingByStorageId } from "../../../common/api/booking";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import CreateStore from "../store/create/CreateStore";

const PartnerBusiness = () => {
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [bookingData, setBookingData] = useState([]);
  const [storageData, setStorageData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStorage, setSelectedStorage] = useState(null);

  const queryClient = useQueryClient();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const storageQuery = useQuery({
    queryKey: ["storage"],
    queryFn: getStorageByUserId,
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Store Name",
      dataIndex: "title",
      key: "name",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Luggage count",
      dataIndex: "capacity",
      key: "capacity",
    },

    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      //render: (_, { tags }) => (
      //   <>
      //     {tags.map((tag) => {
      //       let color = tag.length > 9 ? "geekblue" : "green";
      //       if (tag === "canceled") {
      //         color = "volcano";
      //       }
      //       return (
      //         <Tag color={color} key={tag}>
      //           {tag.toUpperCase()}
      //         </Tag>
      //       );
      //     })}
      //   </>
      // ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, data) => (
        <Space size="middle">
          <span
            onClick={() => {
              GetBookingByStorageId(data.id);
              setIsModalVisible(true);
            }}
          >
            View
          </span>
          <a>Update</a>
        </Space>
      ),
    },
  ];

  const data = storageQuery.data?.data?.success.data;

  const handleTableChange = (pagination, filters, sorter) => {
    // Update the current page state
    setCurrent(pagination.current);
    setPageSize(pagination.pageSize);

    console.log("Pagination:", pagination);
    console.log("Filters:", filters);
    console.log("Sorter:", sorter);

    console.log(pageSize);

    // You can add additional logic here, such as fetching new data based on pagination
  };

  const searchFilters = [
    { key: "name", label: "Customer Name" },
    { key: "location", label: "Location" },
    { key: "status", label: "Status" },
  ];

  const handleSearch = (values) => {
    console.log("Search Values:", values); // Log search values or perform search logic here
  };

  const GetBookingByStorageId = async (storageId) => {
    // Fetch booking data for the given storage ID
    const storageData = await queryClient.fetchQuery({
      queryKey: ["storage", storageId],
      queryFn: () => getStorageById(storageId),
    });

    // const bookingData = await queryClient.fetchQuery({
    //   queryKey: ["booking", "storage", storageId],
    //   queryFn: () => getBookingByStorageId(storageId),
    // });

    // Update state with fetched data
    setStorageData(storageData.data?.success.data || []);
    // if (!bookingData.isError) {
    //   setBookingData(bookingData.data?.success.data);
    // }
  };

  const updateStore = (data) => {
    setSelectedStorage(data);
    setIsModalOpen(true);
  };
  console.log(selectedStorage);

  const bookingColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Drop Off",
      dataIndex: "drop_off_date",
      key: "drop_off_date",
    },
    {
      title: "Pick Up",
      dataIndex: "pick_up_date",
      key: "pick_up_date",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      render: (_, { pick_up_date, drop_off_date }) =>
        pick_up_date - drop_off_date,
    },
    {
      title: "Luggage count",
      dataIndex: "luggage_size",
      key: "luggage_size",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      // render: (_, { tags }) => (
      //   <>
      //     {tags.map((tag) => {
      //       let color = tag.length > 9 ? "geekblue" : "green";
      //       if (tag === "canceled") {
      //         color = "volcano";
      //       }
      //       return (
      //         <Tag color={color} key={tag}>
      //           {tag.toUpperCase()}
      //         </Tag>
      //       );
      //     })}
      //   </>
      // ),
    },
    {
      title: "Payment Status",
      key: "payment",
      dataIndex: "payment",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a>View </a>
          <a onClick={(record) => updateStore(record)}>Update</a>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="booking-container">
        <div>
          <SearchComponent filters={searchFilters} onSearch={handleSearch} />
        </div>
        <div className="booking-table">
          {storageQuery.isLoading ? (
            <Skeleton />
          ) : storageQuery.isError ? (
            <div>Error: {storageQuery.error.response.data.error.message}</div>
          ) : (
            <TableAntd
              columns={columns}
              data={data}
              total={data.length}
              handleTableChange={handleTableChange}
              pageSize={pageSize}
            />
          )}
        </div>
      </div>

      <Modal
        // loading={loading}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width="75%"
      >
        <Descriptions title="Business Info">
          <Descriptions.Item label="Store Name">
            {storageData?.title}
          </Descriptions.Item>
          <Descriptions.Item label="Telephone">
            {storageData?.contact_mobile}
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            {storageData?.status}
          </Descriptions.Item>
          <Descriptions.Item label="Description">
            {storageData?.description}
          </Descriptions.Item>
          <Descriptions.Item label="Address">
            {storageData?.address}
          </Descriptions.Item>
        </Descriptions>
        <br />
        <br />
        <TableAntd
          columns={bookingColumns}
          data={bookingData}
          total={bookingData?.length}
          handleTableChange={handleTableChange}
        />
        <CreateStore
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      </Modal>
    </div>
  );
};

export default PartnerBusiness;
