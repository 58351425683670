/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {
  AreaChartOutlined,
  CustomerServiceOutlined,
  HomeOutlined,
  PhoneOutlined,
  WechatOutlined
} from "@ant-design/icons";
import "./ContactUsCard.css";

const ContactUsCard = ({title, description, icon, contact}) => {

  const icons = {
    'Chat to Sales': <WechatOutlined/>,
    'Chat to Support': <CustomerServiceOutlined/>,
    'Visit Us': <HomeOutlined/>,
    'Call Us': <PhoneOutlined/>
  };

  return (
    <div>
      <div className="contact-us-card-single">
        <div className="card-icon">
          {icons[icon] || <AreaChartOutlined/>} {/* Default icon if no match */}
        </div>
        <div className="card-content">
          <h3 className="card-title">{title}</h3>
          <p className="card-description">{description}</p>
          {contact ? <p className="card-email"><a href={`mailto:${contact}`}>{contact}</a></p> :
            <p className="card-email">&nbsp;</p>
          }
        </div>
      </div>
    </div>
  );
};

export default ContactUsCard;