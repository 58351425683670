import { useEffect, useState } from "react";

import axios from "axios";
import useRefreshToken from "./useRefreshToken";

import { axiosPrivate } from "../configs/axios";
import UseAuth from "./UseAuth";
// Custom hook for API calls with request cancellation and interceptors
const useAxios = () => {
  const [loading, setLoading] = useState(false);

  const { auth, setAuth } = UseAuth();

  const refresh = useRefreshToken(auth?.refreshToken);

  //Navigate and Location hook
  // const navigate = useNavigate();
  // const location = useLocation();
  // const from = location.state?.from?.pathname || "/";

  useEffect(() => {
    // Eject previous interceptors to avoid multiple bindings
    const requestInterceptor = axiosPrivate.interceptors.request.use(
      (config) => {
        if (auth) {
          config.headers["Authorization"] = `Bearer ${auth.accessToken}`;
        }
        console.log("Sending request to:", config.url);
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseInterceptor = axiosPrivate.interceptors.response.use(
      (response) => {
        console.log("Received response from:", response.config.url);
        return response;
      },
      async (error) => {
        const originalRequest = error?.config;
        if (
          error?.response?.status === 401 &&
          !originalRequest?.retry &&
          auth?.refreshToken
        ) {
          originalRequest.retry = true;
          const response = await refresh();
          setAuth({ ...auth, accessToken: response.data.accessToken });
          originalRequest.headers[
            "Authorization"
          ] = `Bearer ${response.data.accessToken}`;
          return axiosPrivate(originalRequest);
        }
        return Promise.reject(error);
      }
    );

    // Clean up interceptors when `auth` changes or component unmounts
    return () => {
      axiosPrivate.interceptors.request.eject(requestInterceptor);
      axiosPrivate.interceptors.response.eject(responseInterceptor);
    };
  }, [auth, refresh, setAuth]);

  // // Set up request and response interceptors
  // const requestInterceptor = axiosPrivate.interceptors.request.use(
  //   (config) => {
  //     if (auth) {
  //       config.headers["Authorization"] = `Bearer ${auth.accessToken}`;
  //     }
  //     // Log or modify request here
  //     console.log("Sending request to:", config.url);
  //     return config;
  //   },
  //   (error) => {
  //     // Handle request error here
  //     return Promise.reject(error);
  //   }
  // );

  // const responseInterceptor = axiosPrivate.interceptors.response.use(
  //   (response) => {
  //     // Log or modify response here
  //     console.log("Received response from:", response.config.url);
  //     return response;
  //   },
  //   async (error) => {
  //     const originalRequest = error?.config;
  //     if (
  //       error?.response?.status === 401 &&
  //       !originalRequest?.retry &&
  //       auth?.refreshToken
  //     ) {
  //       originalRequest.retry = true;
  //       const response = await refresh();
  //       setAuth({ ...auth, accessToken: response.data.accessToken });
  //       originalRequest.headers[
  //         "Authorization"
  //       ] = `Bearer ${response.data.accessToken}`;
  //       return axios(originalRequest);
  //     }

  //     // Handle response error here
  //     return Promise.reject(error);
  //   }
  // );

  useEffect(() => {
    const source = axios.CancelToken.source();

    return () => {
      // Cancel the request when the component unmounts
      source.cancel("Component unmounted: Request cancelled.");
    };
  }, []);

  // Making the API call with cancellation support
  const request = async ({
    url,
    method,
    data = {},
    params = {},
    requestConfig = {},
  }) => {
    setLoading(true);
    try {
      const result = await axiosPrivate({
        url,
        method,
        data: method.toLowerCase() === "get" ? undefined : data, // Only include data for non-GET requests
        params: method.toLowerCase() === "get" ? data : params, // For GET requests, use data as query params
        headers: requestConfig,
        cancelToken: axios.CancelToken.source().token,
      });

      return result.data;
    } catch (error) {
      if (!error?.response) {
        // navigate("/error", { replace: true });
        return {
          status: false,
          error: { message: "Server Error" },
        };
      }
      console.log("Request cancelled", error.response.data);
      return error.response.data;
    } finally {
      setLoading(false);
    }
  };

  // Expose the state and the fetchData function
  return { loading, request };
};

export default useAxios;
