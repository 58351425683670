/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useState } from "react";
import { Button, Checkbox, Form, Input, Modal, Spin, Typography } from "antd";
import { LoadingOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
import "./LoginModal.css";

import { API_URLS } from "../../configs/api.urls";
import UseAuth from "../../hooks/UseAuth";
import useAxios from "../../hooks/axios";

const { Link, Text } = Typography;

const LoginModal = ({
  isModalVisible,
  handleCancel,
  setRegisterModalVisible,
}) => {
  const { setAuth } = UseAuth();
  const { loading, request } = useAxios();
  const [error, setError] = useState(null);
  const [form] = Form.useForm();

  const handleSubmit = async (value) => {
    setError(null);
    const response = await request({
      url: API_URLS.LOGIN_URL,
      method: "POST",
      data: value,
    });
    if (!response.status) {
      return setError(response.error.message);
    }
    const { userId, userType, accessToken, refreshToken } = response;
    setAuth({ userId, userType, accessToken, refreshToken });
    form.resetFields();
    handleCancel();
  };

  return (
    <>
      <Modal
        title={
          <div style={{ textAlign: "center", marginBottom: "20px" }}>
            Log In
          </div>
        }
        centered
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={form}
          name="login"
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
        >
          <Form.Item
            name="emailAddress"
            rules={[
              { required: true, message: "Please input your email address!" },
              { type: "email", message: "Please enter a valid email address" },
            ]}
          >
            <Input prefix={<UserOutlined />} placeholder="Email Address" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password prefix={<LockOutlined />} placeholder="Password" />
          </Form.Item>

          {error && (
            <Form.Item>{error && <Text type="danger">{error}</Text>}</Form.Item>
          )}

          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Link href="#" style={{ float: "right" }}>
            Forgot password
          </Link>

          <Form.Item className="login-btn">
            <Button
              type="primary"
              htmlType="submit"
              disabled={loading}
              style={{ minWidth: "100px" }}
            >
              {loading ? (
                <Spin indicator={<LoadingOutlined spin />} />
              ) : (
                "Log In"
              )}
            </Button>
          </Form.Item>

          <div style={{ textAlign: "center" }}>
            <Text>or</Text>{" "}
            <Link
              onClick={async () => {
                await form.resetFields();
                setRegisterModalVisible();
                handleCancel();
              }}
            >
              Register now!
            </Link>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default LoginModal;
