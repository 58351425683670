/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const API_URLS = {
  LOGIN_URL: "/auth/login",
  REGISTER_URL: "/auth/register",
  ACCOUNT_VERIFICATION_URL: "/auth/active",
  LOGOUT_URL: "/auth/logout",

  GET_ME_URL: "token/me",

  REFRESH_TOKEN_URL: "token/access/generate",

  UPDATE_USER_URL: "user/update",
  DELETE_USER_URL: "user/delete",
  UPDATE_USER_PASSWORD_URL: "user/update-password",

  CREATE_STORAGE_URL: "storage/create/",
  GET_STORAGE_URL: "storage/user/",
  GET_STORAGE_BY_ID_URL: "storage/",
  GET_STORAGE_NEAR_BY_URL: "storage/nearby/paginated",

  GET_STORAGE_BOOKING_BY_USERID_URL: "booking/user/storage/",
  GET_BOOKING_BY_STORAGE_ID_URL: "booking/storage/",

  CREATE_BANK_ACCOUNT_URL: "account/create/",
  UPDATE_BANK_ACCOUNT_URL: "account/user/",
  GET_BANK_ACCOUNTS_BY_USERID_URL: "account/user/",

  GET_REVIEWS_BY_STORAGE_ID_URL: "review/storage/paginated",
  ADD_REVIEW_URL: "review/create/",

  POST_LIKE_URL: "like/toggle/",

  CREATE_STRIPE_SESSION_URL: "stripe/create-checkout-session",
  GRT_STRIPE_PAYMENT_STATUS_URL: "stripe/session-status",
};
