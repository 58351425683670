/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useEffect, useState} from 'react';
import {Select, Typography} from 'antd';

const {Text} = Typography;
const {Option} = Select;

// List of common time zones with GMT offsets
const timeZones = [
  {label: 'GMT-12:00', value: 'GMT-12:00'},
  {label: 'GMT-11:00', value: 'GMT-11:00'},
  {label: 'GMT-10:00', value: 'GMT-10:00'},
  {label: 'GMT-09:00', value: 'GMT-09:00'},
  {label: 'GMT-08:00', value: 'GMT-08:00'},
  {label: 'GMT-07:00', value: 'GMT-07:00'},
  {label: 'GMT-06:00', value: 'GMT-06:00'},
  {label: 'GMT-05:00', value: 'GMT-05:00'},
  {label: 'GMT-04:00', value: 'GMT-04:00'},
  {label: 'GMT-03:00', value: 'GMT-03:00'},
  {label: 'GMT-02:00', value: 'GMT-02:00'},
  {label: 'GMT-01:00', value: 'GMT-01:00'},
  {label: 'GMT+00:00', value: 'GMT+00:00'},
  {label: 'GMT+01:00', value: 'GMT+01:00'},
  {label: 'GMT+02:00', value: 'GMT+02:00'},
  {label: 'GMT+03:00', value: 'GMT+03:00'},
  {label: 'GMT+04:00', value: 'GMT+04:00'},
  {label: 'GMT+05:00', value: 'GMT+05:00'},
  {label: 'GMT+06:00', value: 'GMT+06:00'},
  {label: 'GMT+07:00', value: 'GMT+07:00'},
  {label: 'GMT+08:00', value: 'GMT+08:00'},
  {label: 'GMT+09:00', value: 'GMT+09:00'},
  {label: 'GMT+10:00', value: 'GMT+10:00'},
  {label: 'GMT+11:00', value: 'GMT+11:00'},
  {label: 'GMT+12:00', value: 'GMT+12:00'},
  {label: 'GMT+13:00', value: 'GMT+13:00'},
  {label: 'GMT+14:00', value: 'GMT+14:00'},
];


const TimeZone = () => {

  const [userTimeZone, setUserTimeZone] = useState('');

  // Detect user's current time zone offset in GMT
  useEffect(() => {
    const offsetMinutes = new Date().getTimezoneOffset();
    const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
    const offsetMinutesRemaining = Math.abs(offsetMinutes) % 60;
    const sign = offsetMinutes <= 0 ? '+' : '-';
    const detectedTimeZone = `GMT${sign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutesRemaining).padStart(2, '0')}`;
    setUserTimeZone(detectedTimeZone);
  }, []);

  const handleTimeZoneChange = (value) => {
    setUserTimeZone(value);
  };

  return (
    <div>
      <div style={{maxWidth: 300, margin: '0 auto', padding: '10px'}}>
        <Text>Your Current Time Zone:</Text>
        <Text strong style={{display: 'block', marginBottom: 8}}>{userTimeZone}</Text>

        <Text>Select a different time zone if needed:</Text>
        <Select
          value={userTimeZone}
          onChange={handleTimeZoneChange}
          style={{width: '100%'}}
        >
          {timeZones.map((tz) => (
            <Option key={tz.value} value={tz.value}>
              {tz.label}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default TimeZone;