/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useState} from "react";
import {Modal, Space} from "antd";
import SearchComponent from "../../../common/search/Search";
import TableAntd from "../../../common/components/table/TableAntd";
import {getStorageByUserId,} from "../../../common/api/storage";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import SingleStoreModal from "./SingleStoreModal";
import "./Stores.css";

const Stores = () => {
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [bookingData, setBookingData] = useState([]);
  const [storageData, setStorageData] = useState(null);

  const queryClient = useQueryClient();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const storageQuery = useQuery({
    queryKey: ["storage"],
    queryFn: getStorageByUserId,
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  const [status, setStatus] = useState(null);  // Set initial status to 'pending'

  const handleConfirm = () => {
    // Change status to 'blocked' when Confirm is clicked
    setStatus('blocked');
  };

  const handleBlock = () => {
    // Perform any logic when Blocked button is clicked
    console.log('Blocked status action');
  };

  const businessHours = {
    "Monday": {
      "open": true,
      "hours": [
        {
          "openTime": "08:00:00",
          "closeTime": "17:00:00"
        }
      ]
    },
    "Tuesday": {
      "open": true,
      "hours": [
        {
          "openTime": "08:00:00",
          "closeTime": "17:00:00"
        }
      ]
    },
    "Wednesday": {
      "open": true,
      "hours": [
        {
          "openTime": "08:00:00",
          "closeTime": "17:00:00"
        }
      ]
    },
    "Thursday": {
      "open": true,
      "hours": [
        {
          "openTime": "08:00:00",
          "closeTime": "17:00:00"
        }
      ]
    },
    "Friday": {
      "open": false,
      "hours": null
    },
    "Saturday": {
      "open": true,
      "hours": [
        {
          "openTime": "08:00:00",
          "closeTime": "17:00:00"
        }
      ]
    },
    "Sunday": {
      "open": true,
      "hours": [
        {
          "openTime": "08:00:00",
          "closeTime": "17:00:00"
        }
      ]
    }
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Store Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Storage Capacity",
      dataIndex: "capacity",
      key: "capacity",
    },
    {
      title: "Hourly Rate",
      dataIndex: "h_rate",
      key: "capacity",
    },
    {
      title: "Daily Rate",
      dataIndex: "d_rate",
      key: "capacity",
    },

    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      //render: (_, { tags }) => (
      //   <>
      //     {tags.map((tag) => {
      //       let color = tag.length > 9 ? "geekblue" : "green";
      //       if (tag === "canceled") {
      //         color = "volcano";
      //       }
      //       return (
      //         <Tag color={color} key={tag}>
      //           {tag.toUpperCase()}
      //         </Tag>
      //       );
      //     })}
      //   </>
      // ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, data) => (
        <Space size="middle">
          <span
            onClick={() => {
              getStorageById(data);
              setIsModalVisible(true);
            }}
          >
            View
          </span>
          <a>Update</a>
        </Space>
      ),
    },
  ];

  const generateMockData = (count = 50) => {
    const names = ['John Brown', 'Jim Green', 'Joe Black', 'Jane Doe', 'Emily White'];
    const addresses = [
      'New York No. 1 Lake Park',
      'London No. 1 Lake Park',
      'Sydney No. 1 Lake Park',
      'Tokyo No. 1 Lake Park',
      'Paris No. 1 Lake Park'
    ];
    const tagOptions = [
      ['rejected'],
      ['pending'],
      ['confirmed'],
    ];
    const bookingDate = [
      '2024-01-12', '2024-06-19', '2024-06-15', '2024-07-15'
    ]

    const generateRandomString = (length = 5) => {
      const characters = 'abcdefghijklmnopqrstuvwxyz';
      let randomString = '';

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomString += characters[randomIndex];
      }

      return randomString;
    }

    return Array.from({length: count}).map((_, i) => ({
      key: (i + 1).toString(),
      id: i + 1,
      name: names[i % names.length] + " " + i,
      contactName: names[i % names.length],
      age: 30 + (i % 15), // Varies age between 30 and 44
      address: addresses[i % addresses.length],
      date: bookingDate[i % bookingDate.length],
      tags: tagOptions[i % tagOptions.length],
      h_rate: "£" + (Math.floor(Math.random() * (10 - 1 + 1)) + 1).toFixed(2),
      d_rate: "£" + (Math.floor(Math.random() * (15 - 3 + 1)) + 3).toFixed(2),
      payment: tagOptions[i % tagOptions.length],
      status: tagOptions[i % tagOptions.length],
      capacity: (Math.floor(Math.random() * (100 - 1 + 1)) + 1),
      telephone: Math.floor(Math.random() * 1e10),
      email: generateRandomString(5) + '@gmail.com',
      website: generateRandomString(8) + '.com',
    }));
  }

  const data = generateMockData();

  const handleTableChange = (pagination, filters, sorter) => {
    // Update the current page state
    setCurrent(pagination.current);
    setPageSize(pagination.pageSize);

    console.log("Pagination:", pagination);
    console.log("Filters:", filters);
    console.log("Sorter:", sorter);

    console.log(pageSize);

    // You can add additional logic here, such as fetching new data based on pagination
  };

  const searchFilters = [
    {key: "name", label: "Customer Name"},
    {key: "location", label: "Location"},
    {key: "status", label: "Status"},
  ];

  const handleSearch = (values) => {
    console.log("Search Values:", values); // Log search values or perform search logic here
  };

  const getStorageById = (data) => {
    setStatus(data.status);
    setStorageData(data)
  }

  // const GetBookingByStorageId = async (storageId) => {
  //   // Fetch booking data for the given storage ID
  //   try {
  //     const storageData = await queryClient.fetchQuery({
  //       queryKey: ["storage", storageId],
  //       queryFn: () => getStorageById(storageId),
  //     });
  //
  //     const bookingData = await queryClient.fetchQuery({
  //       queryKey: ["booking", "storage", storageId],
  //       queryFn: () => getBookingByStorageId(storageId),
  //     });
  //
  //     // Update state with fetched data
  //     setStorageData(storageData.data.success.data || []);
  //     setBookingData(bookingData.data.success.data || []);
  //   } catch (error) {
  //     console.error("Error fetching booking data:", error);
  //     setBookingData([]);
  //   }
  // };

  return (
    <div>
      <div className="admin-store-container">
        <div>
          <SearchComponent filters={searchFilters} onSearch={handleSearch}/>
        </div>
        <div className="booking-table">
          {/*{storageQuery.isLoading ? (*/}
          {/*  <div>Loading...</div>*/}
          {/*) : storageQuery.isError ? (*/}
          {/*  <div>Error: {storageQuery.error.response.data.error.message}</div>*/}
          {/*) : (*/}
          <TableAntd
            columns={columns}
            data={data}
            total={data.length}
            handleTableChange={handleTableChange}
            pageSize={pageSize}
          />
          {/*)}*/}
        </div>
      </div>

      <div className="admin-store-modal-container">
        <Modal
          className="admin-store-modal"
          wrapClassName="admin-store-model"
          // loading={loading}
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >

          <SingleStoreModal storeInfo={storageData} businessHours={businessHours}
                            handleBlock={handleBlock} handleConfirm={handleConfirm}/>
        </Modal>
      </div>
    </div>
  );
};

export default Stores;
