/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Button, Col, Divider, Row, Typography} from "antd";
import React from "react";

const {Title, Text} = Typography;

const SingleStoreModal = ({storeInfo, businessHours, handleConfirm, handleBlock}) => {

  return (
    <div>
      <div style={{ padding: "20px"}}>
        <Title
          level={3}
          style={{
            textAlign: "center",
            marginBottom: "20px",
            borderBottom: "1px solid #000",
          }}
        >
          Store Details
        </Title>

        <Title level={4}>Basic Information</Title>
        <Row style={{textAlign: "start", lineHeight: "5px"}} gutter={[16, 8]}>
          <Col span={10}>
            <Text strong>Store Name:</Text>
          </Col>
          <Col span={14}>
            <Text>{storeInfo.name}</Text>
          </Col>
          <Col span={10}>
            <Text strong>Store Location:</Text>
          </Col>
          <Col span={14}>
            <Text>{storeInfo.address}</Text>
          </Col>
          <Col span={10}>
            <Text strong>Description:</Text>
          </Col>
          <Col span={14}>
            <Text>{storeInfo.description}</Text>
          </Col>

          <Col span={10}>
            <Text strong>Hourly Rate:</Text>
          </Col>
          <Col span={14}>
            <Text>{`£${storeInfo.h_rate}`}</Text>
          </Col>

          <Col span={10}>
            <Text strong>Daily Rate:</Text>
          </Col>
          <Col span={14}>
            <Text>{`£${storeInfo.d_rate}`}</Text>
          </Col>

          <Col span={10}>
            <Text strong>Store Capacity:</Text>
          </Col>
          <Col span={14}>
            <Text>{storeInfo.capacity + " Luggage"}</Text>
          </Col>
        </Row>

        <Divider/>

        <Title level={4}>Store Status</Title>

        <Row style={{textAlign: "start", lineHeight: "5px"}} gutter={[16, 8]}>

          <Col span={10}>
            <Text strong>Status</Text>
          </Col>
          <Col span={8}>
            <Text>{storeInfo.status.toString().toUpperCase()}</Text>
          </Col>
          {/* Conditional rendering based on the status */}
          {storeInfo.status.toLocaleString() === 'confirmed' ? (

            <Button
              className="blocked-btn"
              onClick={handleBlock}
            >
              Block
            </Button>
          ) : (
            <Button
              className="confirm-btn"
              onClick={handleConfirm}
            >
              Confirm
            </Button>
          )}
        </Row>

        <Divider/>

        <Title level={4}>Contact Information</Title>
        <Row style={{textAlign: "start", lineHeight: "5px"}} gutter={[16, 8]}>
          <Col span={10}>
            <Text strong>Contact Name:</Text>
          </Col>
          <Col span={14}>
            <Text>{storeInfo.contactName}</Text>
          </Col>
          <Col span={10}>
            <Text strong>Email:</Text>
          </Col>
          <Col span={14}>
            <Text>{storeInfo.email}</Text>
          </Col>
          <Col span={10}>
            <Text strong>Mobile:</Text>
          </Col>
          <Col span={14}>
            <Text>{storeInfo.telephone}</Text>
          </Col>
          <Col span={10}>
            <Text strong>Website:</Text>
          </Col>
          <Col span={14}>
            <Text>{storeInfo.contactWebsite}</Text>
          </Col>
        </Row>

        <Divider/>

        <Title level={4}>Business Hours</Title>
        <Row style={{textAlign: "start", lineHeight: "5px"}} gutter={[16, 8]}>
          <Col span={10}>
            <Text strong>Always Open:</Text>
          </Col>
          <Col span={14}>
            <Text>
              {Object.values(businessHours).some((day) => !day.open)
                ? "No"
                : "Yes"}
            </Text>
          </Col>
        </Row>
        {Object.keys(businessHours).map((day) => (
          <Row
            style={{textAlign: "start", lineHeight: "10px"}}
            key={day}
            gutter={[16, 8]}
          >
            <Col span={10}>
              <Text strong>{day}:</Text>
            </Col>
            <Col span={14}>
              {businessHours[day].open ? (
                businessHours[day].hours.map((timeSlot, index) => (
                  <div key={index}>
                    <Text>{`${timeSlot.openTime} - ${timeSlot.closeTime}`}</Text>
                  </div>
                ))
              ) : (
                <Text>Closed</Text>
              )}
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
};

export default SingleStoreModal;