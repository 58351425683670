/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Spin,
  Typography,
} from "antd";
import { LoadingOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
import "./RegisterModal.css";

import { API_URLS } from "../../configs/api.urls";
import UseAuth from "../../hooks/UseAuth";
import useAxios from "../../hooks/axios";

const { Link, Text } = Typography;

const RegisterModal = ({
  isModalVisible,
  handleCancel,
  setLoginModalVisible,
}) => {
  const { loading, request } = useAxios();

  const [registerForm] = Form.useForm();

  const handleSubmit = async (value) => {
    const response = await request({
      url: API_URLS.REGISTER_URL,
      method: "POST",
      data: value,
    });

    if (!response.status) {
      const fieldsErrors = Object.keys(response.error).map((field) => ({
        name: field, // Field name in the form
        errors: [response.error[field]], // Error message as an array
      }));

      // Set the errors in the form
      return registerForm.setFields(fieldsErrors);
    }
    message.success(response.success.message);
    handleCancel();
  };

  return (
    <>
      <Modal
        title={
          <div style={{ textAlign: "center", marginBottom: "20px" }}>
            Register
          </div>
        }
        centered
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={registerForm}
          name="register"
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
        >
          <Form.Item
            name="fullName"
            rules={[
              { required: true, message: "Please input your full name!" },
              {
                min: 2,
                max: 25,
                message: "Full name must be between 2 and 25 characters.",
              },
              {
                pattern: /^[A-Za-z]+(?:\s[A-Za-z]+)*$/,
                message:
                  "Full name must contain only alphabetical characters and spaces.",
              },
            ]}
          >
            <Input prefix={<UserOutlined />} placeholder="Full Name" />
          </Form.Item>
          <Form.Item
            name="emailAddress"
            rules={[
              { required: true, message: "Please input your email address!" },
              { type: "email", message: "Please enter a valid email address" },
            ]}
          >
            <Input prefix={<UserOutlined />} placeholder="Email Address" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: "Please input your password!" },
              {
                min: 6,
                max: 128,
                message: "Password must be between 6 and 128 characters.",
              },
            ]}
          >
            <Input.Password prefix={<LockOutlined />} placeholder="Password" />
          </Form.Item>
          <Form.Item
            name="password2"
            dependencies={["password"]}
            rules={[
              { required: true, message: "Please input your password again!" },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    "The two passwords that you entered do not match!"
                  );
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="Confirm Password"
            />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            rules={[
              { required: true, message: "Please input your phone number!" },
              {
                pattern: /[0-9]{10}[0-9]*$/,
                message:
                  "Please enter a valid mobile number(Ex: 911234567890).",
              },
            ]}
          >
            <Input prefix={<UserOutlined />} placeholder="Phone Number" />
          </Form.Item>
          <Form.Item className="login-btn">
            <Button
              type="primary"
              htmlType="submit"
              disabled={loading}
              style={{ minWidth: "100px" }}
            >
              {loading ? (
                <Spin indicator={<LoadingOutlined spin />} />
              ) : (
                "Register"
              )}
            </Button>
          </Form.Item>

          <div style={{ textAlign: "center" }}>
            <Text>or</Text>{" "}
            <Link
              onClick={async () => {
                await registerForm.resetFields();
                setLoginModalVisible();
                handleCancel();
              }}
            >
              Login now!
            </Link>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default RegisterModal;
