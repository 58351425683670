import React, { useEffect, useState } from "react";
import { GetPaymentStatus } from "../../../common/api/stripe";
import { useQuery } from "@tanstack/react-query";

const PaymentStatus = () => {
  const params = new URLSearchParams(window.location.search);
  const sessionId = params.get("session_id");

  const sessionQuery = useQuery({
    queryKey: ["paymentStatus", sessionId],
    queryFn: () => GetPaymentStatus(sessionId),
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  console.log(sessionQuery.data?.data?.paymentIntent);

  return (
    <div>
      <h2>Payment Status</h2>
      <p>
        {sessionQuery.isLoading
          ? "Loading..."
          : sessionQuery.data?.data?.paymentIntent?.status}
      </p>
    </div>
  );
};

export default PaymentStatus;
