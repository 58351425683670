/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useState } from "react";
import { Col, Empty, Row, Skeleton } from "antd";
import DropdownOption from "../../../common/components/dropdown-option/DropdownOption";
import UserBooking from "../bookingcard/UserBooking";
import CreateStore from "../store/create/CreateStore";
import { useQuery } from "@tanstack/react-query";
import { getstoragebookingByUserId } from "../../../common/api/booking";
import { getStorageByUserId } from "../../../common/api/storage";
import moment from "moment";

const PartnerDashboard = () => {
  const bookingQuery = useQuery({
    queryKey: ["bookings"],
    queryFn: getstoragebookingByUserId,
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  const storageQuery = useQuery({
    queryKey: ["storage"],
    queryFn: getStorageByUserId,
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  const bookings = bookingQuery.data?.data?.success.data;

  // [
  //   {
  //     name: 'Strand Strand', status: 'Confirmed', luggage: 2, type: 'Daily', date: 'Nov 30',
  //   },
  //   {
  //     name: 'Taj Palace', status: 'Completed', luggage: 1, type: 'Hourly', date: 'Jun 30',
  //   }
  // ];

  const stores = storageQuery.data?.data?.success.data;

  // [
  //   {
  //     name: "Aida",
  //     status: "Open",
  //     luggage: 200,
  //     type: "Pending",
  //     location: "London",
  //   },
  //   {
  //     name: "Circus",
  //     status: "Closed",
  //     luggage: 100,
  //     type: "Approved",
  //     location: "London",
  //   },
  // ];

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="partner-dashboard-container">
      <div className="booking-dcontainer">
        <Row>
          <Col span={20}>
            <h2>My Bookings</h2>
          </Col>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
            span={4}
          >
            <div>
              <DropdownOption />
              <CreateStore
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
              />
            </div>
          </Col>
          <Col span={24}>
            <div>
              {bookingQuery.isLoading ? (
                <Skeleton />
              ) : bookingQuery.isError ? (
                <Empty />
              ) : (
                bookings?.map((booking, index) => (
                  <UserBooking
                    key={index}
                    name={booking.title}
                    status={booking.status}
                    luggageCount={booking.luggage_size}
                    dateOrLocation={moment(booking.drop_off_date).format(
                      "YYYY/MM/DD HH:mm"
                    )}
                  />
                ))
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={20}>
            <h2>My Stores</h2>
          </Col>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
            span={4}
          >
            <div>
              <DropdownOption setIsModalOpen={setIsModalOpen} />
            </div>
          </Col>
          <Col span={24}>
            <div>
              {storageQuery.isLoading ? (
                <Skeleton />
              ) : storageQuery.data?.data?.success.data.length === 0 ? (
                <Empty />
              ) : (
                stores.map((store, index) => (
                  <UserBooking
                    key={index}
                    name={store.title}
                    status={store.status}
                    luggageCount={store.capacity}
                    frequency={store.opening_hours}
                    dateOrLocation={store.address}
                  />
                ))
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PartnerDashboard;
