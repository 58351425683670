/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useState} from "react";
import TableAntd from "../../../common/components/table/TableAntd";
import {Space, Tag} from "antd";
import SearchComponent from "../../../common/search/Search";

const Booking = () => {
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Store Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Drop Off",
      dataIndex: "drop_off_date",
      key: "drop_off_date",
    },
    {
      title: "Pick Up",
      dataIndex: "pick_up_date",
      key: "pick_up_date",
    },
    {
      title: "Luggage count",
      dataIndex: "luggage_size",
      key: "luggage_size",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            let color = tag.length > 9 ? "geekblue" : "green";
            if (tag === "canceled") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Payment Status",
      key: "payment",
      dataIndex: "payment",
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            let color = tag.length > 9 ? "geekblue" : "green";
            if (tag === "canceled") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a>View</a>
          <a>Update</a>
        </Space>
      ),
    },
  ];
  const generateMockData = (count = 50) => {
    const names = ['John Brown', 'Jim Green', 'Joe Black', 'Jane Doe', 'Emily White'];
    const addresses = [
      'New York No. 1 Lake Park',
      'London No. 1 Lake Park',
      'Sydney No. 1 Lake Park',
      'Tokyo No. 1 Lake Park',
      'Paris No. 1 Lake Park'
    ];
    const tagOptions = [
      ['completed'],
      ['processing'],
      ['canceled'],
      ['completed'],
      ['processing']
    ];
    const bookingDate = [
      '2024-01-12','2024-06-19','2024-06-15','2024-07-15'
    ]

    const  generateRandomDate = (startDate, endDate) => {

      const randomDateTimestamp = Math.floor(Math.random() * (endDate - startDate + 1)) + startDate;

      const randomDate = new Date(randomDateTimestamp);

      const year = randomDate.getFullYear();
      const month = (randomDate.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
      const day = randomDate.getDate().toString().padStart(2, '0');

      return `${year}-${month}-${day}`;
    }

    return Array.from({length: count}).map((_, i) => ({
      key: (i + 1).toString(),
      id: i+1,
      name: names[i % names.length] + " " + i,
      age: 30 + (i % 15), // Varies age between 30 and 44
      address: addresses[i % addresses.length],
      date:bookingDate[i% bookingDate.length],
      tags: tagOptions[i % tagOptions.length],
      amount: "£"+ (Math.floor(Math.random() * (1200 - 50 + 1)) + 50).toFixed(2),
      payment: tagOptions[i % tagOptions.length],
      status: tagOptions[i % tagOptions.length],
      luggage_size: (Math.floor(Math.random() * (25 - 1 + 1)) + 1),
      drop_off_date: generateRandomDate(new Date('2024-01-01').getTime(), new Date('2024-08-01').getTime()),
      pick_up_date: generateRandomDate(new Date('2024-08-01').getTime(), new Date('2024-12-01').getTime())

    }));
  }

  const data = generateMockData();

  const handleTableChange = (pagination, filters, sorter) => {
    // Update the current page state
    setCurrent(pagination.current);
    setPageSize(pagination.pageSize);

    console.log("Pagination:", pagination);
    console.log("Filters:", filters);
    console.log("Sorter:", sorter);

    // You can add additional logic here, such as fetching new data based on pagination
  };

  const searchFilters = [
    { key: "name", label: "Name" },
    { key: "age", label: "Age" },
    { key: "address", label: "Address" },
  ];

  const handleSearch = (values) => {
    console.log("Search Values:", values); // Log search values or perform search logic here
  };

  return (
    <div>
      <div className="booking-container">
        <div>
          <SearchComponent filters={searchFilters} onSearch={handleSearch} />
        </div>
        <div className="booking-table">
          {/*{bookingQuery.isLoading ? (*/}
          {/*  <div>Loading...</div>*/}
          {/*) : bookingQuery.isError ? (*/}
          {/*  <div>Error: {bookingQuery.error.response.data.error.message}</div>*/}
          {/*) : (*/}
            <TableAntd
              columns={columns}
              data={data}
              total={data?.length}
              handleTableChange={handleTableChange}
            />
          {/*)}*/}
        </div>
      </div>
    </div>
  );
};

export default Booking;
