/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import { Grid, Table } from "antd";

const { useBreakpoint } = Grid;

const TableAntd = ({ columns, data, total, handleTableChange, pageSize }) => {
  const screens = useBreakpoint();

  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        onChange={handleTableChange}
        scroll={{ x: screens.xs ? 400 : null }}
        pagination={{
          size: total,
          responsive: true,
          showSizeChanger: true,
          totalBoundaryShowSizeChanger: total,
          pageSize: pageSize,
        }}
      />
    </div>
  );
};

export default TableAntd;
