/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useState } from "react";
import { Button, Divider, List, Rate, Skeleton, Tag, Typography } from "antd";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  WifiOutlined,
} from "@ant-design/icons";
import StorePriceModal from "../StorePrice/StorePriceModal";
import { useNavigate, useParams } from "react-router-dom";
import "./StoreSection.css";
import LuggagePopup from "../../modal/LuggagePopup";
import { useQuery } from "@tanstack/react-query";
import { getStorageById } from "../../../../common/api/storage";

const { Title, Text, Paragraph } = Typography;

const getRandomLocation = () => {
  const lat = (44.5 + Math.random() * 0.5).toFixed(6); // Latitude range: 44.5 to 45.0
  const lng = (-1.0 + Math.random() * 0.5).toFixed(6); // Longitude range: -1.0 to -0.5
  return { lat: parseFloat(lat), lng: parseFloat(lng) };
};

const storeHours = {
  Monday: {
    open: true,
    hours: [
      {
        openTime: "08:00:00",
        closeTime: "17:00:00",
      },
    ],
  },
  Tuesday: {
    open: true,
    hours: [
      {
        openTime: "08:00:00",
        closeTime: "17:00:00",
      },
    ],
  },
  Wednesday: {
    open: true,
    hours: [
      {
        openTime: "08:00:00",
        closeTime: "17:00:00",
      },
    ],
  },
  Thursday: {
    open: true,
    hours: [
      {
        openTime: "08:00:00",
        closeTime: "14:00:00",
      },
      {
        openTime: "16:00:00",
        closeTime: "19:00:00",
      },
    ],
  },
  Friday: {
    open: false,
    hours: null,
  },
  Saturday: {
    open: true,
    hours: [
      {
        openTime: "08:00:00",
        closeTime: "17:00:00",
      },
    ],
  },
  Sunday: {
    open: true,
    hours: [
      {
        openTime: "08:00:00",
        closeTime: "17:00:00",
      },
    ],
  },
};

// Data Object
// const luggageHeroData = {
//   title: "Crypto Luggage - Paris Square",
//   tag: "SUPERHERO",
//   tags: [
//     { label: "Highly Rated", color: "gold" },
//     { label: "Open 24/hr", color: "green" },
//     { label: "Open Late", color: "blue" },
//   ],
//   rating: 4,
//   ratingCount: 808,
//   reviewCount: 509,
//   pricing: {
//     hourlyRate: "£1.19/hour",
//     dailyRate: "£4/day",
//     detailsLink: "#",
//   },
//   openingHours: storeHours,
//   about: [
//     { text: "Free Wi-Fi", icon: <WifiOutlined />, color: "blue" },
//     {
//       text: "Each bag is insured for up to £2,200",
//       icon: <CheckCircleOutlined />,
//       color: "green",
//     },
//     {
//       text: "Flexible booking",
//       icon: <ClockCircleOutlined />,
//       color: "volcano",
//     },
//   ],
//   howItWorks: {
//     description:
//       "Crypto Luggage storage the easy way. Make a booking and go to the location to drop off your bags." +
//       " Pick them back up whenever you want within the listed opening hours.",
//     moreInfoLink: "#",
//   },
//   notRelevant: {
//     message:
//       "Not relevant for you? If this place doesn't suit your needs, check out our many other storage locations.",
//     moreShopsLink: "#",
//   },
//   location: getRandomLocation(),
// };

// const pricingData = {
//   title: "Price per bag",
//   prices: [
//     { amount: "£1.19", unit: "hour" },
//     { amount: "£4", unit: "day" },
//   ],
//   details: [
//     "You have the flexibility to choose between hourly or daily rates when making your booking.",
//     "Prices apply to bookings made in advance. Walk-in rates may be higher",
//     "Discounts are automatically applied for multi-day bookings and large online reservations",
//   ],
//   buttonText: "OK, got it",
// };

const formatHours = (hours) => {
  if (!hours || hours.length === 0) return "Closed";
  return hours
    .map(({ openTime, closeTime }) => `${openTime} - ${closeTime}`)
    .join("\n");
};

const StoreSection = ({ onSeeComments }) => {
  const { id } = useParams(); // Extract id from the URL

  const storageQuery = useQuery({
    queryKey: ["storage", id],
    queryFn: () => getStorageById(id),
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  const data = storageQuery.data?.data?.success.data;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Navigates back to the previous page
  };

  // Close the modal
  const handleClose = () => {
    setIsModalVisible(false);
  };

  const [isBookingModalVisible, setBookingModalVisible] = useState(false);
  const [isPaymentPopupVisible, setPaymentPopupVisible] = useState(false);

  const showBookingModal = () => {
    setBookingModalVisible(true);
  };

  const closeBookingModal = () => {
    setBookingModalVisible(false);
  };

  const handleSubmit = () => {
    setPaymentPopupVisible(true);
    closeBookingModal();
  };
  const pricingData = {
    title: "Price per bag",
    prices: [
      data?.pricing?.isHourly
        ? { amount: data?.pricing?.hourlyRate, unit: "hour" }
        : null,
      data?.pricing?.isDaily
        ? { amount: data?.pricing?.dailyRate, unit: "day" }
        : null,
    ],
    details: [
      "You have the flexibility to choose between hourly or daily rates when making your booking.",
      "Prices apply to bookings made in advance. Walk-in rates may be higher",
      "Discounts are automatically applied for multi-day bookings and large online reservations",
    ],
    buttonText: "OK, got it",
  };

  return (
    <>
      {storageQuery.isLoading ? (
        <Skeleton />
      ) : (
        <div
          style={{
            maxWidth: "80vw",
            margin: "20px auto",
            padding: "20px",
            borderRadius: "12px",
          }}
        >
          <div className="title-rating">
            {/* Header */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <Title level={3}>{data.title}</Title>
              </div>
            </div>

            {/* Ratings */}
            <div style={{ margin: "20px 0", paddingTop: "20px" }}>
              <Rate
                disabled
                defaultValue={data.rating}
                style={{ color: "#faad14" }}
              />
              <Text style={{ marginLeft: "8px" }}>({data.rating} ratings)</Text>
              <Paragraph>
                <Text strong>{data.reviews} travelers</Text> gave this place 5
                out of {data.rating} stars!{" "}
                <a
                  href="#"
                  onClick={onSeeComments}
                  style={{ color: "#1890ff" }}
                >
                  See what they said.
                </a>
              </Paragraph>
            </div>
          </div>

          {/* Pricing */}
          <div style={{ margin: "20px 0" }}>
            <Title level={4}>Pricing</Title>
            <Paragraph>
              {/* {data.pricing.hourlyRate} or {data.pricing.dailyRate} per bag. */}
              {data.pricing?.isHourly ? data.pricing.hourlyRate : ""}
              {data.pricing?.isHourly && data.pricing.isDaily ? " or " : ""}
              {data.pricing?.isDaily ? data.pricing.dailyRate : ""} per bag.
              Book ahead to get the best rates.
            </Paragraph>
            <a
              // href={data.pricing.detailsLink}
              onClick={showModal}
              style={{ color: "#1890ff" }}
            >
              Details
            </a>
          </div>
          <Button
            style={{ border: "none" }}
            type="primary"
            className="yellow-btn"
            onClick={showBookingModal}
          >
            Book Now
          </Button>

          {/* Opening Hours */}
          <div style={{ margin: "20px 0" }}>
            <Title level={4}>Opening Hours</Title>
            <List
              dataSource={Object.entries(data.opening_hours)} // Convert storeHours object to an array
              renderItem={([day, { open, hours }]) => (
                <List.Item>
                  <Text style={{ marginRight: "10px" }} strong>
                    {day}{" "}
                  </Text>{" "}
                  {open ? (
                    <>
                      <Tag color="green">OPEN</Tag>
                      <pre
                        style={{
                          margin: "5px 0",
                          fontSize: "medium",
                          fontFamily: "Roboto Mono, monospace",
                        }}
                      >
                        {formatHours(hours)}
                      </pre>
                    </>
                  ) : (
                    <Tag color="red">CLOSED</Tag>
                  )}
                </List.Item>
              )}
            />
          </div>

          {/* About */}
          <div style={{ margin: "20px 0" }}>
            <Title level={4}>About</Title>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "12px" }}>
              {data?.tags?.map((item, index) => (
                <Tag key={index} icon={item.icon} color={item.color}>
                  {item?.label}
                </Tag>
              ))}
            </div>
          </div>

          {/* How It Works */}
          <div style={{ margin: "20px 0" }}>
            <Title level={4}>How it works</Title>
            <Paragraph>{data.description}</Paragraph>
          </div>
          <LuggagePopup
            data={data}
            visible={isBookingModalVisible}
            onClose={closeBookingModal}
            handleSubmit={handleSubmit}
          />
          {isPaymentPopupVisible && (
            <div className="popup-overlay">
              <div className="popup-container">
                <div className="popup-header">
                  <h2>Payment Gateway</h2>
                  <button
                    className="close-button"
                    onClick={() => setPaymentPopupVisible(false)}
                  >
                    &times;
                  </button>
                </div>
                <div className="popup-content">
                  <p>Payment Gateway Under Development</p>
                  <p>Please check again later!</p>
                </div>
              </div>
            </div>
          )}
          {/* Not Relevant */}
          <Divider />
          <div style={{ textAlign: "left" }}>
            {/* <Paragraph>{data.notRelevant.message}</Paragraph> */}
            <Button
              className="yellow-btn"
              onClick={handleGoBack}
              type="primary"
              size="large"
              // href={data.notRelevant.moreShopsLink}
            >
              See more shops
            </Button>
          </div>
          <StorePriceModal
            visible={isModalVisible}
            onClose={handleClose}
            pricing={pricingData}
          />
        </div>
      )}
    </>
  );
};

export default StoreSection;
