/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Form, Input, InputNumber, Typography} from 'antd';
import "./Step.css";


const SecondStep = ({formData}) => {

  return (
    <div className="second-step-form">
      <Typography.Title level={3}>Store Information</Typography.Title>

      {/* Store Name */}
      <Form.Item
        label="Store Name"
        name="storeName"
        className="second-step-store-name"
        rules={[{required: true, message: 'Please enter the store name'}]}
      >
        <Input placeholder="Enter your store name"/>
      </Form.Item>

      {/* Business Registration Number */}
      <Form.Item
        label="Business Registration Number / VAT Number"
        name="registrationNumber"
      >
        <Input placeholder="Enter registration number or VAT number"/>
      </Form.Item>

      <Form.Item
        className="step-store-label"
        layout={"horizontal"}
        label={
          <div className="step-store-name-desc">
            <div>
              <Typography.Text strong>Storage Capacity</Typography.Text>
            </div>
            <div>
              <Typography.Text type="secondary" style={{display: 'inline-block', fontSize: 12}}>
                Storage Space available at your location
              </Typography.Text>
            </div>
          </div>
        }
        name="storeCapacity"
        rules={[{required: true, message: 'Please enter the store capacity'}]}
      >
        <InputNumber min={1} max={100} defaultValue={1}/>
      </Form.Item>

      {/* Daily Rate */}

      <Form.Item
        className="step-store-label"
        layout="horizontal"
        label={
          <div className="step-store-name-desc">
            <div>
              <Typography.Text strong>Daily Rate</Typography.Text>
            </div>
            <div>
              <Typography.Text type="secondary" style={{display: 'block', fontSize: 12}}>
                Daily rate for one storage space
              </Typography.Text>
            </div>
          </div>
        }
        name="dailyRate"
        rules={[{required: true, message: 'Please enter the daily rate'}]}
      >
        <InputNumber min={1} defaultValue={1} placeholder="Rate"/>
      </Form.Item>

      {/* Hourly Rate */}

      <Form.Item
        className="step-store-label"
        layout="horizontal"
        label={
          <div className="step-store-name-desc">
            <div>
              <Typography.Text strong>Hourly Rate</Typography.Text>
            </div>
            <div>
              <Typography.Text type="secondary" style={{display: 'block', fontSize: 12}}>
                Hourly rate for one storage space
              </Typography.Text>
            </div>
          </div>
        }
        name="hourlyRate"
        rules={[{required: true, message: 'Please enter the hourly rate'}]}
      >
        <InputNumber min={1} defaultValue={1} placeholder="Rate"/>
      </Form.Item>


      {/*/!* Store Image *!/*/}
      {/*<Form.Item*/}
      {/*  label="Store Image"*/}
      {/*  name="storeImage"*/}
      {/*>*/}
      {/*  <Upload*/}
      {/*    listType="picture-card"*/}
      {/*    maxCount={3}*/}
      {/*    accept=".jpg,.jpeg,.png"*/}
      {/*  >*/}
      {/*    <div>*/}
      {/*      <PlusOutlined/>*/}
      {/*      <div style={{marginTop: 8}}>New Image</div>*/}
      {/*    </div>*/}
      {/*  </Upload>*/}
      {/*</Form.Item>*/}
      {/*</Form>*/}
    </div>
  );
};

export default SecondStep;