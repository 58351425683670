/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useState } from "react";
import TableAntd from "../../../common/components/table/TableAntd";
import { Skeleton, Space, Tag } from "antd";
import SearchComponent from "../../../common/search/Search";
import "./Bookings.css";
import { useQuery } from "@tanstack/react-query";
import { getstoragebookingByUserId } from "../../../common/api/booking";

const Booking = () => {
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const bookingQuery = useQuery({
    queryKey: ["bookings"],
    queryFn: getstoragebookingByUserId,
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Store Name",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Drop Off",
      dataIndex: "drop_off_date",
      key: "drop_off_date",
    },
    {
      title: "Pick Up",
      dataIndex: "pick_up_date",
      key: "pick_up_date",
    },
    {
      title: "Luggage count",
      dataIndex: "luggage_size",
      key: "luggage_size",
    },
    {
      title: "Amount",
      dataIndex: "total_charge",
      key: "total_charge",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      // render: (_, { tags }) => (
      //   <>
      //     {tags.map((tag) => {
      //       let color = tag.length > 9 ? "geekblue" : "green";
      //       if (tag === "canceled") {
      //         color = "volcano";
      //       }
      //       return (
      //         <Tag color={color} key={tag}>
      //           {tag.toUpperCase()}
      //         </Tag>
      //       );
      //     })}
      //   </>
      // ),
    },
    {
      title: "Payment Status",
      key: "payment_status",
      dataIndex: "payment_status",
      // render: (_, { tags }) => (
      //   <>
      //     {tags.map((tag) => {
      //       let color = tag.length > 9 ? "geekblue" : "green";
      //       if (tag === "canceled") {
      //         color = "volcano";
      //       }
      //       return (
      //         <Tag color={color} key={tag}>
      //           {tag.toUpperCase()}
      //         </Tag>
      //       );
      //     })}
      //   </>
      // ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a>View</a>
          <a>Update</a>
        </Space>
      ),
    },
  ];

  const data = bookingQuery.data?.data?.success.data;

  const handleTableChange = (pagination, filters, sorter) => {
    // Update the current page state
    setCurrent(pagination.current);
    setPageSize(pagination.pageSize);

    console.log("Pagination:", pagination);
    console.log("Filters:", filters);
    console.log("Sorter:", sorter);

    // You can add additional logic here, such as fetching new data based on pagination
  };

  const searchFilters = [
    { key: "name", label: "Name" },
    { key: "age", label: "Age" },
    { key: "address", label: "Address" },
  ];

  const handleSearch = (values) => {
    console.log("Search Values:", values); // Log search values or perform search logic here
  };

  return (
    <div>
      <div className="booking-container">
        <div>
          <SearchComponent filters={searchFilters} onSearch={handleSearch} />
        </div>
        <div className="booking-table">
          {bookingQuery.isLoading ? (
            <Skeleton />
          ) : (
            <TableAntd
              columns={columns}
              data={data}
              total={data?.length}
              handleTableChange={handleTableChange}
              isLoading={bookingQuery.isLoading}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Booking;
