/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React from "react";
import { Modal, Button, Typography } from "antd";

const { Title, Text } = Typography;

const StorePriceModal = ({ visible, onClose, pricing }) => {
  return (
    <Modal
      title={<h3>{pricing.title}</h3>}
      open={visible}
      onCancel={onClose}
      footer={[
        <Button
          key="ok"
          type="primary"
          onClick={onClose}
          style={{ backgroundColor: "#00aaff", border: "none" }}
        >
          OK
        </Button>,
      ]}
    >
      {/* Prices Section */}
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <Title level={2} style={{ fontSize: "1.5rem", margin: 0 }}>
          {pricing.prices.map((price, index) => (
            <span key={index}>
              {price?.amount}
              {/* <span style={{ fontSize: "1.5rem", fontWeight: "normal" }}>{price.unit}</span> */}
              {price?.amount && index < pricing.prices.length - 1 && (
                <span style={{ opacity: 0.5, margin: "0 8px" }}>|</span>
              )}
            </span>
          ))}
        </Title>
      </div>

      {/* Details List */}
      <ul style={{ fontSize: "1rem", paddingLeft: "20px" }}>
        {pricing.details.map((detail, index) => (
          <li key={index}>
            {index === 1 ? (
              <>
                Prices apply to bookings made in advance.{" "}
                <Text strong style={{ color: "red" }}>
                  Walk-in rates may be higher
                </Text>
              </>
            ) : (
              detail
            )}
          </li>
        ))}
      </ul>
    </Modal>
  );
};

export default StorePriceModal;
