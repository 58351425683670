/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useState} from 'react';
import {Button, Modal, Space, Tag} from "antd";
import SearchComponent from "../../../common/search/Search";
import TableAntd from "../../../common/components/table/TableAntd";
import UserModal from "./UserModal";
import "./Users.css";
import CreateUser from "./CreateUser";

const Users = () => {
  const [current, setCurrent] = useState(1);
  const [status, setStatus] = useState(null);  // Set initial status to 'pending'
  const [userData, setUserData] = useState(null);
  const [isCreateUserModalVisible, setIsCreateUserModalVisible] = useState(false);

  const showCreateUserModal = () => {
    setIsCreateUserModalVisible(true);
  };

  const handleCancel = () => {
    setIsCreateUserModalVisible(false);
  };

  const handleConfirm = () => {
    // Change status to 'blocked' when Confirm is clicked
    setStatus('blocked');
  };

  const handleBlock = () => {
    // Perform any logic when Blocked button is clicked
    console.log('Blocked status action');
  };

  const getUserData = (data) => {
    setStatus(data.status);
    setUserData(data)
  }

  const [isModalVisible, setIsModalVisible] = useState(false);

  const columns = [
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, { status }) => (
        <>
          {status.map((tag) => {
            let color = tag.length > 9 ? "geekblue" : "green";
            if (tag === "BLOCKED") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Registered Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "User Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Action",
      key: "action",
      render: (_, data) => (
        <Space size="middle">
          <Button onClick={() => {
            getUserData(data)
            setIsModalVisible(true)}
          } >View</Button>
        </Space>
      ),
    },
  ];

  const generateMockData = (count = 50) => {
    const names = [
      "John Brown",
      "Jim Green",
      "Joe Black",
      "Jane Doe",
      "Emily White",
    ];
    const addresses = [
      "New York No. 1 Lake Park",
      "London No. 1 Lake Park",
      "Sydney No. 1 Lake Park",
      "Tokyo No. 1 Lake Park",
      "Paris No. 1 Lake Park",
    ];
    const tagOptions = [
      ["ACTIVE"],
      ["ACTIVE"],
      ["ACTIVE"],
      ["BLOCKED"],
    ];

    const userOptions = [
      ["user"],
      ["user"],
      ["manager"],
    ];

    const bookingDate = [
      "2024-01-12",
      "2024-06-19",
      "2024-06-15",
      "2024-07-15",
    ];

    const generateRandomString = (length = 5) => {
      const characters = 'abcdefghijklmnopqrstuvwxyz';
      let randomString = '';

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomString += characters[randomIndex];
      }

      return randomString;
    }

    return Array.from({ length: count }).map((_, i) => ({
      key: (i + 1).toString(),
      name: names[i % names.length] + " " + i,
      address: addresses[i % addresses.length],
      date: bookingDate[i % bookingDate.length],
      status: tagOptions[i % tagOptions.length],
      type: userOptions[i % userOptions.length].toString().toUpperCase(),
      telephone: Math.floor(Math.random() * 1e10),
      email: generateRandomString(5) + '@gmail.com',
      amount:
        "£" +
        ((30 + i / 15) * 156)
          .toFixed(3)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    }));
  };

  const data = generateMockData();

  const handleTableChange = (pagination, filters, sorter) => {
    // Update the current page state
    setCurrent(pagination.current);

    console.log("Pagination:", pagination);
    console.log("Filters:", filters);
    console.log("Sorter:", sorter);

    // You can add additional logic here, such as fetching new data based on pagination
  };

  const searchFilters = [
    { key: "name", label: "User Name" },
    { key: "date", label: "Date" },
    { key: "status", label: "Status" },
  ];

  const handleSearch = (values) => {
    console.log("Search Values:", values); // Log search values or perform search logic here
  };

  return (
    <div>
      <div className="admin-user-container">
        <div>
          <SearchComponent filters={searchFilters} onSearch={handleSearch} />
        </div>
        <div style={{marginBottom: "10px"}}>
          <Button type="primary" onClick={showCreateUserModal} style={{marginTop: '5px'}}>
            Add New User
          </Button>
        </div>
        <div className="admin-user-table">
          <TableAntd
            columns={columns}
            data={data}
            total={data.length}
            handleTableChange={handleTableChange}
          />
        </div>
      </div>
      <div>
        <Modal
          className="admin-user-modal"
          // loading={loading}
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
          width="600px"
        >

          <UserModal userInfo={userData}
                            handleBlock={handleBlock} handleConfirm={handleConfirm}/>
        </Modal>
      </div>
      <div>
        <CreateUser setIsCreateUserModalVisible={showCreateUserModal}
                    handleCancel={handleCancel}
                    isCreateUserModalVisible={isCreateUserModalVisible}/>
      </div>
    </div>
  );
};

export default Users;