/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Button, Dropdown, Space} from 'antd';
import {EllipsisOutlined} from '@ant-design/icons';

const MoreOptionsDropdown = ({setIsModalOpen}) => {

  const showModal = () => {
    setIsModalOpen(true);
  };

  const items = [
    {
      label: 'View More',
      key: 'viewMore',
    },
    {
      label: 'Add Store',
      key: 'addStore',
      onClick: (() => {
        showModal()
      })
    }
  ];

  const handleMenuClick = (e) => {
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Space wrap>
      <Dropdown menu={menuProps} trigger={['click']} placement="bottomRight">
        <Button type="text" icon={<EllipsisOutlined style={{fontSize: "25px", color: "black"}}/>}/>
      </Dropdown>
    </Space>
  );
};

export default MoreOptionsDropdown;