/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Form, Input, Button, notification, Card, Typography} from 'antd';
import './ContactUs.css';
import {MailOutlined, MessageOutlined, UserOutlined} from "@ant-design/icons";
import ContactUsHeader from "../ContactUsHeader/ContactUsHeader";

const {Title} = Typography;
const {TextArea} = Input;

const ContactUs = () => {

  const onFinish = (values) => {
    // Handle form submission logic here
    console.log('Form Submitted:', values);

    // Display success notification
    notification.success({
      message: 'Message Sent!',
      description: 'Thank you for contacting us. We will get back to you shortly.',
    });
  };

  return (
    <div className="contact-us-body">
        <ContactUsHeader/>
          <div className="contact-us-container">
            <Card className="contact-us-card" bordered={false}>
              {/* Header Text */}
              <div className="contact-us-header">
                <Title level={2} className="contact-us-title">
                  We&apos;d Love to Help You!
                </Title>
                <p className="contact-us-description">Please fill out the form below and we will get back to you as soon
                  as
                  possible.</p>
              </div>

              {/* Contact Form */}
              <Form
                name="contact_us"
                onFinish={onFinish}
                layout="vertical"
                className="contact-us-form"
              >
                {/* Subject Field */}
                <Form.Item
                  label="Subject"
                  name="subject"
                  rules={[{required: true, message: 'Please enter the subject!'}]}
                >
                  <Input
                    prefix={<MessageOutlined/>}
                    placeholder="Enter the subject"
                    className="contact-us-input"
                  />
                </Form.Item>

                {/* Name Field */}
                <Form.Item
                  label="Your Name"
                  name="name"
                  rules={[{required: true, message: 'Please enter your name!'}]}
                >
                  <Input
                    prefix={<UserOutlined/>}
                    placeholder="Enter your name"
                    className="contact-us-input"
                  />
                </Form.Item>

                {/* Email Field */}
                <Form.Item
                  label="Your Email"
                  name="email"
                  rules={[
                    {required: true, message: 'Please enter your email!'},
                    {type: 'email', message: 'Please enter a valid email!'},
                  ]}
                >
                  <Input
                    prefix={<MailOutlined/>}
                    placeholder="Enter your email"
                    className="contact-us-input"
                  />
                </Form.Item>

                {/* Message Field */}
                <Form.Item
                  label="Your Message"
                  name="message"
                  rules={[{required: true, message: 'Please enter your message!'}]}
                >
                  <TextArea
                    rows={4}
                    placeholder="Enter your message"
                    className="contact-us-input"
                  />
                </Form.Item>

                {/* Submit Button */}
                <Form.Item>
                  <Button type="primary" htmlType="submit" block className="contact-us-submit-btn yellow-btn">
                    Send Message
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </div>
    </div>
  );
};

export default ContactUs;