/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useState } from "react";
import {Button, Space, Tag} from "antd";
import SearchComponent from "../../../common/search/Search";
import TableAntd from "../../../common/components/table/TableAntd";
import TransactionModal from "./transactionModal/TransactionModal";

const Transaction = () => {
  const [current, setCurrent] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentTransaction, setCurrentTransaction] = useState(null);

  const handleViewTransaction = (transaction) => {
    setCurrentTransaction(transaction);
    setModalVisible(true);
  };

  // Handle closing modal
  const handleCloseModal = () => {
    setModalVisible(false);
    setCurrentTransaction(null);
  };

  // Process the payment for a debit transaction
  const handleProcessPayment = (transaction) => {
    console.log(`Processing payment for ${transaction.hotelName} amount ₹${transaction.amount}`);
    // Update the status of the transaction to "Processed"
    transaction.status = 'Processed';
    setModalVisible(false);
  };

  const columns = [
    {
      title: "Store Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Payment Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, { status }) => (
        <>
          {status.map((tag) => {
            let color = tag.length > 9 ? "geekblue" : "green";
            if (tag === "canceled") {
              color = "volcano";
            }
            console.log(tag);
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Action",
      key: "action",
      render: (_, data) => (
        <Space size="middle">
          <Button onClick={() => handleViewTransaction(data)} >View</Button>
        </Space>
      ),
    },
  ];

  const generateMockData = (count = 50) => {
    const names = [
      "John Brown",
      "Jim Green",
      "Joe Black",
      "Jane Doe",
      "Emily White",
    ];
    const addresses = [
      "New York No. 1 Lake Park",
      "London No. 1 Lake Park",
      "Sydney No. 1 Lake Park",
      "Tokyo No. 1 Lake Park",
      "Paris No. 1 Lake Park",
    ];
    const tagOptions = [
      ["processed"],
      ["pending"],
      ["canceled"],
      ["processed"],
      ["processing"],
    ];

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const bookingDate = [
      "2024-01-12",
      "2024-06-19",
      "2024-06-15",
      "2024-07-15",
    ];

    const paymentCategory = [
      "credit",
      "debit",
      "debit"
    ];

    return Array.from({ length: count }).map((_, i) => ({
      key: (i + 1).toString(),
      name: "Store " + names[i % names.length] + " " + i,
      month: months[i % months.length],
      address: addresses[i % addresses.length],
      date: bookingDate[i % bookingDate.length],
      status: tagOptions[i % tagOptions.length],
      category: paymentCategory[i % paymentCategory.length],
      amount:
        "£" +
        ((30 + i / 15) * 156)
          .toFixed(3)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    }));
  };

  const data = generateMockData();

  const handleTableChange = (pagination, filters, sorter) => {
    // Update the current page state
    setCurrent(pagination.current);

    console.log("Pagination:", pagination);
    console.log("Filters:", filters);
    console.log("Sorter:", sorter);

    // You can add additional logic here, such as fetching new data based on pagination
  };

  const searchFilters = [
    { key: "name", label: "Store Name" },
    { key: "date", label: "Date" },
    { key: "status", label: "Status" },
  ];

  const handleSearch = (values) => {
    console.log("Search Values:", values); // Log search values or perform search logic here
  };

  return (
    <div>
      <div className="booking-container">
        <div>
          <SearchComponent filters={searchFilters} onSearch={handleSearch}/>
        </div>

        {/*<div>*/}
        {/*  {data.map((transaction) => (*/}
        {/*    <div key={transaction.id}>*/}
        {/*      <p>*/}
        {/*        <strong>{transaction.category === 'credit' ? 'Credit' : 'Debit'}</strong>*/}
        {/*        <br/>*/}
        {/*        Amount: ₹{transaction.amount} | Hotel: {transaction.hotelName} | Date: {transaction.date}*/}
        {/*        <br/>*/}
        {/*        <Button onClick={() => handleViewTransaction(transaction)}>View Details</Button>*/}
        {/*      </p>*/}
        {/*    </div>*/}
        {/*  ))}*/}
        {/*</div>*/}

        {currentTransaction && (
          <TransactionModal
            transaction={currentTransaction}
            visible={modalVisible}
            onClose={handleCloseModal}
            onProcessPayment={handleProcessPayment}
          />
        )}
        <div className="booking-table">
          <TableAntd
            columns={columns}
            data={data}
            total={data.length}
            handleTableChange={handleTableChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Transaction;
