/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  message,
  Row,
  Switch,
  TimePicker,
  Typography,
} from "antd";
import TimeZone from "./TimeZone";

const { Text } = Typography;

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const FourthStep = ({ days, setDays }) => {
  const [alwaysOpen, setAlwaysOpen] = useState(false);

  const handleAlwaysOpenChange = (checked) => {
    setAlwaysOpen(checked);
    setDays(
      daysOfWeek.reduce((acc, day) => {
        acc[day] = { ...days[day], open: checked };
        return acc;
      }, {})
    );
  };

  const handleDaySwitch = (day, checked) => {
    setDays({
      ...days,
      [day]: { ...days[day], open: checked },
    });
    if (checked === false) {
      setAlwaysOpen(false);
    }
  };

  const isTimeConflict = (
    day,
    newOpenTime,
    newCloseTime,
    indexToExclude = -1
  ) => {
    const existingSlots = days[day].hours;
    for (let i = 0; i < existingSlots.length; i++) {
      if (i === indexToExclude) continue; // Skip current slot in case of editing
      const { openTime, closeTime } = existingSlots[i];
      if (openTime && closeTime) {
        if (newOpenTime.isBefore(closeTime) && newCloseTime.isAfter(openTime)) {
          return true;
        }
      }
    }
    return false;
  };

  const handleTimeChange = (day, index, field, time) => {
    const newHours = [...days[day].hours];
    newHours[index][field] = time;
    if (
      newHours[index].openTime &&
      newHours[index].closeTime &&
      isTimeConflict(
        day,
        newHours[index].openTime,
        newHours[index].closeTime,
        index
      )
    ) {
      message.error("Time slot conflicts with an existing slot.");
      newHours[index][field] = null; // Reset conflicting field
      return;
    }
    newHours[index][field] = time;
    setDays({
      ...days,
      [day]: { ...days[day], hours: newHours },
    });
  };

  const addTimeSlot = (day) => {
    setDays({
      ...days,
      [day]: {
        ...days[day],
        hours: [...days[day].hours, { openTime: null, closeTime: null }],
      },
    });
  };

  const removeTimeSlot = (day, index) => {
    const newHours = [...days[day].hours];
    newHours.splice(index, 1);
    setDays({
      ...days,
      [day]: { ...days[day], hours: newHours },
    });
  };

  return (
    <>
      <Typography.Title level={3}>Business Hours</Typography.Title>

      <Form.Item>
        <>
          <Text>
            Set your weekly hours below to inform travelers when they can drop
            off and pick up their bags.
          </Text>
        </>
      </Form.Item>

      {/* <Form.Item>
        <TimeZone/>
      </Form.Item> */}

      <Form.Item label="Regular Hours">
        <Switch checked={alwaysOpen} onChange={handleAlwaysOpenChange} />
        <Text style={{ marginLeft: 8 }}>
          {alwaysOpen ? "Always Open" : "Closed"}
        </Text>
      </Form.Item>

      {daysOfWeek.map((day) => (
        <Form.Item key={day} label={day} required>
          <Row align="middle" gutter={16}>
            <Col>
              <Switch
                checked={days[day].open || alwaysOpen}
                onChange={(checked) => handleDaySwitch(day, checked)}
              />
            </Col>
            <Col>
              <Text type={days[day].open ? "success" : "danger"}>
                {days[day].open ? "Open" : "Closed"}
              </Text>
            </Col>
            <Col>
              <Button
                type="link"
                disabled={!days[day].open}
                onClick={() => addTimeSlot(day)}
              >
                + Add
              </Button>
            </Col>
          </Row>
          {days[day].open &&
            days[day].hours.map((slot, index) => (
              <Row key={index} gutter={16} style={{ marginBottom: 8 }}>
                <Col span={10}>
                  <Form.Item label="Open">
                    <TimePicker
                      value={slot.openTime}
                      onChange={(time) =>
                        handleTimeChange(day, index, "openTime", time)
                      }
                      format="h:mm A"
                      use12Hours
                      disabled={!days[day].open}
                    />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item label="Close">
                    <TimePicker
                      value={slot.closeTime}
                      onChange={(time) =>
                        handleTimeChange(day, index, "closeTime", time)
                      }
                      format="h:mm A"
                      use12Hours
                      disabled={!days[day].open}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  {days[day].hours.length > 1 && (
                    <Button
                      type="link"
                      danger
                      onClick={() => removeTimeSlot(day, index)}
                    >
                      Remove
                    </Button>
                  )}
                </Col>
              </Row>
            ))}
        </Form.Item>
      ))}
    </>
  );
};

export default FourthStep;
