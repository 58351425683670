import { API_URLS } from "../configs/api.urls";
import { axiosPrivate } from "../configs/axios";

export const toggleLike = async (data) => {
  return await axiosPrivate({
    url: API_URLS.POST_LIKE_URL,
    method: "POST",
    data: data,
  });
};
