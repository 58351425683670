/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useRef, useState} from "react";
import {Button, DatePicker, Input, Select} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import "./SearchBar.css";
import {StandaloneSearchBox, useJsApiLoader} from "@react-google-maps/api";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import {googleMapsConfig} from "../../configs/googleMap";

const { Option } = Select;

const SearchBar = () => {
  const [searchData, setSearchData] = useState({
    date: "",
    luggage: 1,
    lat: "",
    lng: "",
    address: "",
  });

  const inputRef = useRef(null);
  const [address, setAddress] = useState("");

  const { isLoaded } = useJsApiLoader({
    id: googleMapsConfig.id,
    googleMapsApiKey: googleMapsConfig.googleMapsApiKey,
    libraries: googleMapsConfig.libraries,
  });

  const handlePlaceChanged = () => {
    if(inputRef.current){
      const place = inputRef.current.getPlaces()[0];
      const selectedAddress = place.formatted_address || place.name;
      const lat = place.geometry?.location?.lat();
      const lng = place.geometry?.location?.lng(); // Use formatted_address if available
      setAddress(selectedAddress); // Update the input field with the selected address
      setSearchData((prev) => ({
        ...prev,
        lat: lat,
        lng: lng,
        address: selectedAddress,
      }));
    }
  };

  const navigate = useNavigate();

  const handleChange = (name, value) => {
    setSearchData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSearch = () => {
    const queryParams = new URLSearchParams(searchData).toString();
    navigate(`/booking?${queryParams}`);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf('day');
  };

  return (
    <div className="search-bar-container">
      <div className="search-bar">
        <div className="search-item location">
          <label>Location</label>
          {isLoaded && (
            <StandaloneSearchBox
              onLoad={(ref) => (inputRef.current = ref)}
              onPlacesChanged={handlePlaceChanged}
            >
              <Input
                placeholder="Enter location"
                onChange={(e) => setAddress(e.target.value)}
                value={address}
                variant="borderless"
              />
            </StandaloneSearchBox>
          )}
        </div>
        <div className="divider" />
        <div className="search-item date centered-datepicker">
          <label>Date</label>
          <DatePicker
            onChange={(date) =>
              handleChange("date", date ? date.toString() : "")
            }
            format="MM/DD/YYYY"
            variant="borderless"
            size={"large"}
            disabledDate={disabledDate}
            placement={"bottomRight"}
          />
        </div>
        <div className="divider" />
        <div className="search-item luggage">
          <label>Luggage</label>
          <Select
            name="luggage"
            defaultValue="1"
            onChange={(value) => handleChange("luggage", value)}
            variant="filled"
          >
            {[...Array(50).keys()].map((num) => (
              <Option key={num + 1} value={num + 1}>
                {num + 1}
              </Option>
            ))}
          </Select>
        </div>
        <Button type="primary" className="search-button" onClick={handleSearch}>
          <span className="search-icon">
            <SearchOutlined />
          </span>
          <span className="search-text">Search</span>
        </Button>
      </div>
    </div>
  );
};

export default SearchBar;
