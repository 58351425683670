/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./page/home";
import GlobalLayout from "./page/globalLayout/GlobalLayout";
import RequireAuth from "./page/requireAuth/RequireAuth";
import Unauthorized from "./page/unauthorized/Unauthorized";
import Partner from "./page/partner";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import MyAccount from "./common/components/myaccount/MyAccount";
import Book from "./page/booking";
import EmailVerify from "./page/emailVerify/EmailVerify";
import StoreView from "./page/booking/store/StoreView";
import ContactUs from "./page/contactUs/ContactUsForm/ContactUs";
import Admin from "./page/admin/Admin";
import PaymentStatus from "./page/booking/paymentStatus/PaymentStatus";

const ROLES = {
  User: "user",
  Admin: "admin",
};

const Layout = () => {
  return (
    <main className="App">
      <GlobalLayout />
    </main>
  );
};

function App() {
  return (
    <div className="App">
      <Routes>
        {/* public routes */}
        <Route path="/" element={<Layout />}>
          {/*<Route path="reset-password/:id/:token" element={<ResetPassword />} />*/}
          <Route index element={<Home />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/booking" element={<Book />} />
          <Route path="/store/:id" element={<StoreView />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/return" element={<PaymentStatus />} />
          <Route
            element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.User]} />}
          >
            <Route path="account">
              <Route index element={<MyAccount />} />
            </Route>
          </Route>
          <Route
            element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Admin]} />}
          >
            <Route path="partner">
              <Route index element={<Partner />} />
            </Route>
          </Route>
        </Route>
        {/*</Route>*/}
        <Route path="active/:id/:token" element={<EmailVerify />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        {/* protected routes */}
      </Routes>
      <ReactQueryDevtools />
    </div>
  );
}

export default App;
