import React, { useCallback, useEffect, useState } from "react";

import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { Button, Card, Tag, Typography } from "antd";
import {
  CheckCircleFilled,
  ClockCircleFilled,
  CloseCircleOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import "./PlaceCard.css";
import LuggagePopup from "../../../page/booking/modal/LuggagePopup"; // Include the CSS file for styling

const { Text } = Typography;

const PlaceCard = ({ handleClick, place, storeView }) => {
  const { id, title, subtitle, pricing, rating, reviews, open, tags } = place;

  const [isModalVisible, setModalVisible] = useState(false);
  const [isPaymentPopupVisible, setPaymentPopupVisible] = useState(false);

  // const showModal = () => {
  //   setModalVisible(true);
  // };

  // const closeModal = () => {
  //   setModalVisible(false);
  // };

  const handleSubmit = () => {
    setPaymentPopupVisible(true);
    // closeModal();
  };

  return (
    <>
      <Card onClick={() => handleClick(place)} className="place-card">
        {/* Title and Subtitle */}
        <div className="place-card-header">
          <div>
            <Typography.Title level={5} style={{ margin: 0 }}>
              {title}
            </Typography.Title>
            <Text type="secondary">{subtitle}</Text>
          </div>
          <div>
            <Text className="rating">
              {rating} <StarOutlined style={{ color: "#ffc107" }} /> ({reviews}{" "}
              reviews)
            </Text>
          </div>
        </div>

        {/* Tags Section */}
        <div className="place-card-tags">
          {/* Main Tags */}
          <div className="main-tags">
            {tags?.map((tag, index) =>
              tag.label === "Highly Rated" && rating <= 4.0 ? null : (
                <Tag
                  icon={
                    tag.label === "Highly Rated" ? (
                      <StarFilled />
                    ) : tag.label === "Open Late" ? (
                      <ClockCircleFilled />
                    ) : null
                  }
                  key={index}
                  color={tag.color}
                >
                  {tag.label}
                </Tag>
              )
            )}
          </div>

          {/* Status Tag */}
          <div className="status-tag">
            {!open ? (
              <Tag icon={<CloseCircleOutlined />} color="red">
                Closed
              </Tag>
            ) : (
              <Tag icon={<CheckCircleFilled />} color="green">
                Open
              </Tag>
            )}
          </div>
        </div>

        <div>
          <a onClick={() => storeView(id)}>More Info</a>
        </div>
        {/* Price Section */}
        <div className="place-card-footer">
          <div className="price">
            <Text strong>
              {pricing.isHourly ? `£ ${pricing.hourlyRate}/hour` : null}{" "}
              {pricing.isDaily ? `£ ${pricing.dailyRate}/day` : null}
            </Text>
          </div>
          <Button
            onClick={() => setModalVisible(true)}
            type="primary"
            className="book-now yellow-btn"
          >
            Book Now
          </Button>
        </div>
      </Card>
      <LuggagePopup
        data={place}
        visible={isModalVisible}
        onClose={() => setModalVisible(false)}
        handleSubmit={handleSubmit}
        isPaymentPopupVisible={isPaymentPopupVisible}
        setPaymentPopupVisible={setPaymentPopupVisible}
      />
    </>
  );
};

export default PlaceCard;
