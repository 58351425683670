/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useState } from "react";
import {
  Avatar,
  Button,
  Input,
  List,
  message,
  Rate,
  Spin,
  Tooltip,
} from "antd";
import { Comment } from "@ant-design/compatible";
import { LikeFilled, LikeOutlined } from "@ant-design/icons";
import "./CommentSection.css";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { AntNotification } from "../../../../common/components/notification/notification";
import { useParams } from "react-router-dom";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  addReview,
  getreviewsByStorageId,
} from "../../../../common/api/review";
import { toggleLike } from "../../../../common/api/like";
import UseAuth from "../../../../common/hooks/UseAuth";
import { getCurrentUser } from "../../../../common/api/user";

dayjs.extend(relativeTime);

const { TextArea } = Input;

const getColorFromLetter = (letter) => {
  const colors = [
    "#f56a00",
    "#7265e6",
    "#ffbf00",
    "#00a2ae",
    "#87d068",
    "#13c2c2",
    "#eb2f96",
    "#fa8c16",
    "#52c41a",
    "#722ed1",
  ];
  const charCode = letter.toUpperCase().charCodeAt(0); // Get ASCII value
  const index = charCode % colors.length; // Map ASCII to color array index
  return colors[index];
};

const firstNames = [
  "Aria",
  "Kai",
  "Luna",
  "Milo",
  "Zara",
  "Theo",
  "Nova",
  "Jude",
  "Ivy",
  "Ezra",
  "Fiona",
  "Silas",
  "Elara",
  "Orion",
  "Sage",
  "Leif",
  "Astrid",
  "Cyrus",
  "Nyla",
  "Rowan",
];

const lastNames = [
  "Ashwood",
  "Briar",
  "Cavendish",
  "Draven",
  "Everhart",
  "Frost",
  "Grayson",
  "Haven",
  "Ironwood",
  "Jasper",
  "Kingsley",
  "Larkspur",
  "Moonstone",
  "Nightingale",
  "Oakwood",
  "Peregrine",
  "Quill",
  "Ravenwood",
  "Sterling",
  "Thorne",
];

// Function to generate a random character name
function generateCharacterName() {
  const firstName = firstNames[Math.floor(Math.random() * firstNames.length)];
  const lastName = lastNames[Math.floor(Math.random() * lastNames.length)];
  return `${firstName} ${lastName}`;
}

const allComments = Array.from({ length: 20 }, (_, i) => ({
  author: Array.from({ length: 1 }, generateCharacterName),
  content: `This is comment number ${i + 1}.`,
  datetime: `2024-11-${23 - (i % 30)} 04:05:33`, // Simulated dates
  likes: Math.floor(Math.random() * 100), // Random likes
  userLiked: false, // Default to not liked
  rating: Math.ceil(Math.random() * 5), // Random rating from 1 to 5
}));

const CommentSection = ({ isLoggedIn, onSeeComments }) => {
  const { auth } = UseAuth();

  // const [comments, setComments] = useState(allComments);

  const [newComment, setNewComment] = useState("");
  const [newRating, setNewRating] = useState(0); // State for rating

  const [displayedComments, setDisplayedComments] = useState(
    allComments.slice(0, 10)
  );
  const [hasMore, setHasMore] = useState(allComments.length > 10);

  const queryClient = useQueryClient();
  const toggleLikeMutation = useMutation({
    mutationFn: toggleLike,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["review", id], { exact: true });
      message.success(data.data.success.message);
    },
    onError: (error) => {
      message.error(error.data.error.message);
    },
  });

  const addCommentMutation = useMutation({
    mutationFn: addReview,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["review"], { exact: true });
      message.success(data.data.success.message);
      setNewComment("");
      setNewRating(0);
    },
    onError: (error) => {
      console.log(error);
      message.error(error.data.error.message);
    },
  });

  // Handle like action
  const handleLike = (reviewId) => {
    // const updatedComments = [...displayedComments];
    // const comment = updatedComments[index];
    // comment.userLiked = !comment.userLiked;
    // comment.likes += comment.userLiked ? 1 : -1;
    if (!auth) {
      return AntNotification(
        "warning",
        "Warning",
        "Please log in to toggle like"
      );
    }
    toggleLikeMutation.mutate({
      reviewId,
      userId: auth?.userId ? auth?.userId : 0,
    });
    // setDisplayedComments(updatedComments);
  };

  const handleLoadMore = () => {
    const currentLength = displayedComments.length;
    const nextComments = allComments.slice(currentLength, currentLength + 10);
    setDisplayedComments((prevComments) => [...prevComments, ...nextComments]);
    setHasMore(currentLength + 10 < allComments.length);
  };

  // Handle adding a new comment
  const handleAddComment = () => {
    if (!newComment.trim()) {
      AntNotification("warning", "Warning", "Comment cannot be Empty");
      return;
    }
    if (newRating === 0) {
      AntNotification("warning", "Warning", "Please provide a rating!");
      return;
    }

    addCommentMutation.mutate({
      storageId: id,
      rating: newRating,
      comment: newComment,
    });

    // setLoading(true);

    // setTimeout(() => {
    //   setDisplayedComments([
    //     {
    //       author: "You",
    //       content: newComment,
    //       datetime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    //       likes: 0,
    //       userLiked: false,
    //       rating: newRating,
    //     },
    //     ...displayedComments,
    //   ]);
    //   setNewComment("");
    //   setLoading(false);
    //   AntNotification("success", "Success", "Comment added!");
    //   onSeeComments();
    // }, 1000); // Simulate network delay
  };

  const { id } = useParams(); // Extract id from the URL

  // // const reviewdata = {
  // const reviewQuery = useQuery({
  //   queryKey: ["review", id],
  //   queryFn: () =>
  //     getreviewsByStorageId({
  //       storageId: id,
  //       userId: auth?.userId ? auth?.userId : 0,
  //     }),
  //   staleTime: 1000 * 60 * 15, // 15 minutes
  // });

  const {
    status,
    error,
    data,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: ["reviews", id, "infinite"],
    queryFn: ({ pageParam = 1 }) =>
      getreviewsByStorageId({
        storageId: id,
        userId: auth?.userId || 0,
        page: pageParam,
        limit: 10, // Number of reviews per page
      }),
    getNextPageParam: (lastPage) => lastPage.nextPage, // Get the next page number from the API response
  });

  // const data = reviewQuery.data?.data?.success.data;

  const currentUserQuery = useQuery({
    queryKey: ["currentUser"],
    queryFn: auth ? getCurrentUser : () => null,
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  const currentUser = currentUserQuery.data?.data?.success.data;

  return (
    <div
      style={{
        // backgroundColor: '#f5f5f5',
        padding: "20px",
        borderRadius: "8px",
        maxWidth: "100%",
        margin: "20px auto",
      }}
    >
      <div>
        <h2>Comments</h2>

        {/* List of comments */}
        <List
          dataSource={data?.pages.flatMap((page) => page.data) || []}
          renderItem={(item, index) => (
            <>
              <Comment
                author={item.author}
                avatar={
                  <Avatar
                    style={{
                      backgroundColor: getColorFromLetter(item.author[0][0]), // Dynamic background color
                      color: "#fff",
                    }}
                  >
                    {item.author[0][0].toUpperCase()}
                  </Avatar>
                }
                content={
                  <>
                    <p>{item.content}</p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {/*<span style={{ fontWeight: 'bold', marginRight: '8px' }}></span>*/}
                      <Rate
                        disabled
                        value={item.rating}
                        style={{
                          marginTop: "5px",
                          fontSize: "16px",
                          color: "#faad14",
                        }}
                      />{" "}
                      {/* Display rating */}
                    </div>
                  </>
                }
                datetime={
                  <Tooltip title={item.datetime}>
                    <span>{dayjs(item.datetime).fromNow()}</span>{" "}
                    {/* Format datetime as "X time ago" */}
                  </Tooltip>
                }
                actions={[
                  <Tooltip key="like" title="Like">
                    <span
                      onClick={() => handleLike(item.id)}
                      style={{ cursor: "pointer" }}
                    >
                      {item.userLiked ? (
                        <LikeFilled
                          style={{ color: "#1890ff", fontSize: "20px" }}
                        />
                      ) : (
                        <LikeOutlined style={{ fontSize: "18px" }} />
                      )}
                      <span style={{ marginLeft: 4 }}>{item.likes}</span>
                    </span>
                  </Tooltip>,
                ]}
              />
              <hr />
            </>
          )}
        />
        {isFetchingNextPage ? (
          <Spin style={{ display: "block", margin: "16px auto" }} />
        ) : (
          hasNextPage && (
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <Button className="load-more-btn" onClick={() => fetchNextPage()}>
                Load More
              </Button>
            </div>
          )
        )}
      </div>

      {/* Add new comment (if logged in) */}
      {auth ? (
        <div
          style={{
            // background: '#ffffff',
            padding: "20px",
            borderRadius: "12px",
            border: "1px solid #e5e5e5",
            boxShadow: "0 2px 3px rgba(0, 0, 0, 0.1)",
            maxWidth: "100%",
            margin: "20px auto",
          }}
        >
          <h3
            style={{ marginBottom: "16px", fontWeight: "bold", color: "#333" }}
          >
            Add a Comment
          </h3>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <Avatar
              style={{
                backgroundColor: getColorFromLetter("you"),
                marginRight: "12px",
              }}
              size={40}
            >
              {currentUser?.full_name[0][0].toUpperCase()}
            </Avatar>
            <TextArea
              placeholder="Write your comment here..."
              autoSize={{ minRows: 1, maxRows: 5 }}
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              style={{
                flex: 1,
                backgroundColor: "#f9f9f9",
                borderRadius: "8px",
                border: "1px solid #d9d9d9",
                padding: "8px 12px",
                outline: "none",
              }}
            />
          </div>
          <div
            className="rating-section"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Rate
                value={newRating}
                onChange={(value) => setNewRating(value)}
                style={{ fontSize: "20px", color: "#faad14" }}
              />
            </div>
            <Button
              type="primary"
              loading={addCommentMutation.isLoading}
              onClick={handleAddComment}
              style={{
                backgroundColor: "#FFD700",
                color: "#333",
                fontWeight: "bold",
                border: "none",
                borderRadius: "8px",
              }}
            >
              Add Comment
            </Button>
          </div>
        </div>
      ) : (
        <p style={{ marginTop: "20px", fontStyle: "italic", color: "#888" }}>
          Please log in to add a comment.
        </p>
      )}
    </div>
  );
};

export default CommentSection;
