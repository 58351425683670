import axios from "../configs/axios";
import {API_URLS} from "../configs/api.urls";

const useRefreshToken = (refreshToken) => {
  if (refreshToken !== null) {
    return async () => {
      return await axios.post(
        API_URLS.REFRESH_TOKEN_URL,
        {refreshToken: refreshToken},
        {
          withCredentials: true,
        }
      );
    };
  }
};

export default useRefreshToken;
