import { API_URLS } from "../configs/api.urls";
import { axiosPrivate } from "../configs/axios";

export const getStorageByUserId = async () => {
  return await axiosPrivate({
    url: API_URLS.GET_STORAGE_URL,
    method: "GET",
  });
};

export const getStorageById = async (id) => {
  return await axiosPrivate({
    url: API_URLS.GET_STORAGE_BY_ID_URL + id,
    method: "GET",
  });
};

// export const getStorageNearby = async (data) => {
//   return await axiosPrivate({
//     url:
//       API_URLS.GET_STORAGE_NEAR_BY_URL +
//       `?longitude=${data.longitude}&latitude=${data.latitude}`,
//     method: "GET",
//     data: data,
//   });
// };

export const getStorageNearby = async ({
  longitude,
  latitude,
  page = 1,
  limit = 10,
}) => {
  return await axiosPrivate({
    url: `${API_URLS.GET_STORAGE_NEAR_BY_URL}?longitude=${longitude}&latitude=${latitude}&page=${page}&limit=${limit}`,
    method: "GET",
  });
};

export const CreateStorage = async (data) => {
  return await axiosPrivate({
    url: API_URLS.CREATE_STORAGE_URL,
    method: "POST",
    data: data,
  });
};
