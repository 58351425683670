/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useState } from "react";
import {Card, Col, Row, Statistic} from "antd";
import DropdownOption from "../../../common/components/dropdown-option/DropdownOption";
import UserBooking from "../bookingcard/UserBooking";
import CreateStore from "../store/create/CreateStore";
import { useQuery } from "@tanstack/react-query";
import { getstoragebookingByUserId } from "../../../common/api/booking";
import { getStorageByUserId } from "../../../common/api/storage";
import moment from "moment";
import "./AdminDashboard.css";
import Icon from "antd/es/icon";

const AdminDashboard = () => {
  // const bookingQuery = useQuery({
  //   queryKey: ["booking"],
  //   queryFn: getstoragebookingByUserId,
  //   staleTime: 1000 * 60 * 15, // 15 minutes
  // });

  // const storageQuery = useQuery({
  //   queryKey: ["storage"],
  //   queryFn: getStorageByUserId,
  //   staleTime: 1000 * 60 * 15, // 15 minutes
  // });

  const bookings = [
    {
      name: 'Strand Strand', status: 'Confirmed', luggage: 2, type: 'Daily', date: 'Nov 30',
    },
    {
      name: 'Taj Palace', status: 'Completed', luggage: 1, type: 'Hourly', date: 'Jun 30',
    }
  ];

  const stores = [
    {
      name: "Aida",
      status: "Open",
      luggage: 200,
      type: "Pending",
      location: "London",
    },
    {
      name: "Circus",
      status: "Closed",
      luggage: 100,
      type: "Approved",
      location: "London",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const currentUsers = 1234;
  const lastWeekUsers = 1170;

  const currentSales = 12345;
  const lastWeekSales = 11200;

  const currentOrders = 345;
  const lastWeekOrders = 365;

  // Calculate the percentage change for each
  const calculateChange = (current, last) => {
    const change = ((current - last) / last) * 100;
    return change.toFixed(2);
  };

  return (
    <div className="admin-dashboard-container">

      <div className="dashboard-container">
        <Row gutter={[16,16]}>

          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Card className="card-item">
              <div className="card-header">Users</div>
              <div className="card-content">
                <Row justify="space-between" align="middle">
                  <Col>
                    <Statistic
                      title="Total users"
                      value={currentUsers}
                      style={{ fontSize: '32px', fontWeight: 600 }}
                    />
                  </Col>
                  <Col>
                    <div className="change">
                      {/* Display the change in percentage */}
                      <span
                        style={{
                          color: currentUsers > lastWeekUsers ? 'green' : 'red',
                        }}
                      >
                      <Icon
                        type={currentUsers > lastWeekUsers ? 'arrow-up' : 'arrow-down'}
                        style={{ marginRight: 5 }}
                      />
                        {calculateChange(currentUsers, lastWeekUsers)}%
                    </span>
                      <br />
                      {/* Display the last week's value */}
                      <span style={{ fontSize: '14px', color: '#888' }}>
                      Last Week: {lastWeekUsers} Users
                    </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>



          {/* Card 2 */}
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Card className="card-item">
              <div className="card-header">Sales</div>
              <div className="card-content">
                <Row justify="space-between" align="middle">
                  <Col>
                    <Statistic
                      title="Total Transactions"
                      value={currentSales}
                      prefix="£"
                      style={{ fontSize: '32px', fontWeight: 600 }}
                    />
                  </Col>
                  <Col>
                    <div className="change">
                    <span
                      style={{
                        color: currentSales > lastWeekSales ? 'green' : 'red',
                      }}
                    >
                      <Icon
                        type={currentSales > lastWeekSales ? 'arrow-up' : 'arrow-down'}
                        style={{ marginRight: 5 }}
                      />
                      {calculateChange(currentSales, lastWeekSales)}%
                    </span>
                      <br />
                      <span style={{ fontSize: '14px', color: '#888' }}>
                      Last Week: £{lastWeekSales}
                    </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>


          {/* Card 3 */}
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Card className="card-item">
              <div className="card-header">Orders</div>
              <div className="card-content">
                <Row justify="space-between" align="middle">
                  <Col>
                    <Statistic
                      title="Total Bookings"
                      value={currentOrders}
                      style={{ fontSize: '32px', fontWeight: 600 }}
                    />
                  </Col>
                  <Col>
                    <div className="change">
                    <span
                      style={{
                        color: currentOrders > lastWeekOrders ? 'green' : 'red',
                      }}
                    >
                      <Icon
                        type={currentOrders > lastWeekOrders ? 'arrow-up' : 'arrow-down'}
                        style={{ marginRight: 5 }}
                      />
                      {calculateChange(currentOrders, lastWeekOrders)}%
                    </span>
                      <br />
                      <span style={{ fontSize: '14px', color: '#888' }}>
                      Last Week: {lastWeekOrders}
                    </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>


      <div className="booking-dcontainer">
        <Row>
          <Col span={20}>
            <h2>Recent Bookings Updated</h2>
          </Col>
          <Col style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}} span={4}>
            <div>
              <DropdownOption/>
              <CreateStore isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>
            </div>
          </Col>
          <Col span={24}>
            <div>
              {bookings.map((booking, index) => (
                <UserBooking
                  key={index}
                  name={booking.name}
                  status={booking.status}
                  luggageCount={booking.luggage}
                  frequency={booking.type}
                  dateOrLocation={booking.date}
                />
              ))}
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={20}>
            <h2>New Stores</h2>
          </Col>
          <Col style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}} span={4}>
            <div>
              <DropdownOption setIsModalOpen={setIsModalOpen}/>
            </div>
          </Col>
          <Col span={24}>
            <div>
              {stores.map((store, index) => (
                <UserBooking
                  key={index}
                  name={store.name}
                  status={store.status}
                  luggageCount={store.luggage}
                  frequency={store.type}
                  dateOrLocation={store.location}
                />
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AdminDashboard;
