/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import { Form, Input, Select, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import "./Step.css";

const { Option } = Select;

const InitialStep = ({ form, formData, handleChange }) => {
  return (
    <div className="initial-step-form">
      {/*<Form layout="vertical"*/}
      {/*      initialValues={formData}*/}
      {/*      form={form}*/}

      {/*>*/}
      <Typography.Title level={3}>Store Location</Typography.Title>
      {/* Location Search */}
      <Form.Item>
        <Input
          prefix={<SearchOutlined />}
          placeholder="Search Google Maps"
          style={{ borderRadius: 8, marginBottom: 16 }}
        />
      </Form.Item>

      {/* Google Maps Image Placeholder */}
      <div
        style={{
          width: "100%",
          height: "200px",
          backgroundImage:
            "url(https://maps.gstatic.com/tactile/basepage/pegman_sherlock.png)",
          backgroundSize: "cover",
          borderRadius: 8,
          marginBottom: 24,
        }}
      />

      {/* Address Fields */}
      <Form.Item name="address1" label="Address 1">
        <Input placeholder="Address 1" style={{ borderRadius: 8 }} />
      </Form.Item>

      <Form.Item name="address2" label="Address 2">
        <Input placeholder="Address 2" style={{ borderRadius: 8 }} />
      </Form.Item>

      <Form.Item name="postalCode" label="Postal Code">
        <Input placeholder="Postal Code" style={{ borderRadius: 8 }} />
      </Form.Item>

      {/* Country Selector */}
      <Form.Item name="country" label="Country">
        <Select placeholder="Select a country" style={{ borderRadius: 8 }}>
          <Option value="usa">United States</Option>
          <Option value="canada">Canada</Option>
          <Option value="mexico">Mexico</Option>
          {/* Add more countries as needed */}
        </Select>
      </Form.Item>

      {/* Description */}
      <Form.Item name="description" label="Description">
        <TextArea
          rows={4}
          placeholder="Additional details of the located store for direction"
          style={{ borderRadius: 8 }}
        />
      </Form.Item>
      {/*</Form>*/}
    </div>
  );
};

export default InitialStep;
