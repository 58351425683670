/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import TabModel from "../../common/components/Tab/Tab";
import PartnerDashboard from "./dashboard/PartnerDashboard";
import Booking from "./booking/Booking";
import PartnerBusiness from "./business/PartnerBusiness";
import PartnerTransaction from "./transaction/PartnerTransaction";

const Partner = () => {

  const items = [
    {
      key: '1',
      label: 'Dashboard',
      children: <PartnerDashboard />,
    },
    {
      key: '2',
      label: 'Booking',
      children: <Booking />,
    },
    {
      key: '3',
      label: 'Business',
      children: <PartnerBusiness />,
    },
    {
      key: '4',
      label: 'Transaction',
      children: <PartnerTransaction />,
    },
  ];

  return (
    <div>
      <TabModel items={items}/>
    </div>
  );
};

export default Partner;