/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useEffect, useState } from "react";
import { MenuOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";

import "./User.css";
import LoginModal from "../../login/LoginModal";
import UseAuth from "../../../hooks/UseAuth";
import useAxios from "../../../hooks/axios";
import { API_URLS } from "../../../configs/api.urls";
import RegisterModal from "../../register/RegisterModal";
import { useQuery } from "@tanstack/react-query";
import { getCurrentUser } from "../../../api/user";

const User = () => {
  const { auth, setAuth } = UseAuth();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLogingModalVisible, setIsLoginModalVisible] = useState(false);
  const [isRegisterModalVisible, setIsRegisterModalVisible] = useState(false);

  const [userId, setUserId] = useState(null);

  useEffect(() => {
    setIsLoggedIn(auth ? true : false);
    if (auth) {
      setUserId(auth?.userId);
    } else {
      setUserId(null);
    }
  }, [auth]);

  const currentUserQuery = useQuery({
    queryKey: ["currentUser", userId],
    queryFn: getCurrentUser,
    staleTime: 1000 * 60 * 30, // 60
  });

  const userName = currentUserQuery.data?.data?.success.data["full_name"];

  const handleLogout = async () => {
    try {
      setAuth(null);
    } catch (err) {
      console.log(err);
    }
  };

  const menuItems = isLoggedIn ? (
    <Menu>
      <Menu.Item key="1">
        <div className="user-greeting">
          <div className="welcome-text">Welcome!</div>
          <div className="user-name-text">{userName}</div>
        </div>
      </Menu.Item>
      <Menu.Item key="partner">
        <a href="/partner">My Booking</a>
      </Menu.Item>
      <Menu.Item key="account">
        <a href="/account">My Account</a>
      </Menu.Item>
      <Menu.Item key="logout" onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  ) : (
    <Menu>
      <Menu.Item key="login">
        <a onClick={() => setIsLoginModalVisible(true)}>Login</a>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="navbar">
      <div className="navbar-links">
        {auth ? null : (
          <h4 onClick={() => setIsRegisterModalVisible(true)}>
            Become a Partner
          </h4>
        )}
        <Dropdown
          className="dropdown"
          overlay={menuItems}
          overlayClassName="user-dropdown"
          trigger={["click"]}
        >
          {/*<Button icon={<UserOutlined />} shape="circle" />*/}
          <UserOutlined className="icon" />
        </Dropdown>
        <Dropdown
          className="dropdown"
          overlayClassName="user-dropdown"
          overlay={menuItems}
          trigger={["click"]}
        >
          {/*<Button icon={<UserOutlined />} shape="circle" />*/}
          <Button className="menu-button" icon={<MenuOutlined />} />
        </Dropdown>
      </div>

      <LoginModal
        isModalVisible={isLogingModalVisible}
        handleCancel={() => setIsLoginModalVisible(false)}
        setRegisterModalVisible={() => setIsRegisterModalVisible(true)}
      />
      <RegisterModal
        isModalVisible={isRegisterModalVisible}
        handleCancel={() => setIsRegisterModalVisible(false)}
        setLoginModalVisible={() => setIsLoginModalVisible(true)}
      />
    </div>
  );
};

export default User;
