import React, { useCallback, useEffect, useState } from "react";
import { Tag, Typography } from "antd";
import { ClockCircleFilled, StarFilled, StarOutlined } from "@ant-design/icons";
import "./LuggagePopup.css";
import { AntNotification } from "../../../common/components/notification/notification";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CreateStripeSession } from "../../../common/api/stripe";
import UseAuth from "../../../common/hooks/UseAuth";

const { Text } = Typography;

const PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(PUBLIC_KEY);

const LuggagePopup = ({ visible, onClose, data }) => {
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const { auth } = UseAuth();

  const [bags, setBags] = useState(1); // Default number of bags
  const [insurance, setInsurance] = useState(true);
  const [timeOption, setTimeOption] = useState(
    data?.pricing?.isHourly ? "hourly" : "daily"
  ); // "hourly" or "daily"
  const [days, setDays] = useState(1); // Number of days (default: 1)
  const [hours, setHours] = useState(1); // Number of hours (default: 0)
  const [totalPrice, setTotalPrice] = useState(0); // Total price calculation
  const [dropOffDate, setDropOffDate] = useState(getTodayDate()); // Drop-off date
  const [pickUpDate, setPickUpDate] = useState(getTodayDate()); // Pick-up date

  const [isPaymentPopupVisible, setPaymentPopupVisible] = useState(false);
  console.log(days);

  const fetchClientSecret = async () => {
    console.log("Submitting days", days);
    // Create a Checkout Session
    const res = await CreateStripeSession({
      storageId: data.id,
      totalPrice: totalPrice,
      luggageSize: bags,
      bookingType: timeOption,
      days: days,
      hours: hours,
      dropOffDate: dropOffDate,
      pickUpDate: pickUpDate,
    });

    if (res.data.status === false) {
      setPaymentPopupVisible(false);
      return AntNotification("Error", "Invalid Data", res.data.error.message);
    }
    return res.data.clientSecret;
  };

  const options = { fetchClientSecret };

  useEffect(() => {
    if (dropOffDate && pickUpDate) {
      const dropDate = new Date(dropOffDate);
      const pickDate = new Date(pickUpDate);
      const differenceInTime = pickDate - dropDate;
      const calculatedDays = Math.ceil(
        differenceInTime / (1000 * 60 * 60 * 24)
      );
      console.log("calculatedDays", calculatedDays);
      setDays(calculatedDays > 0 ? calculatedDays : 1); // Ensure non-negative days
    }
  }, [dropOffDate, pickUpDate]);

  // Calculate total price when bags, rate, time option, days, or hours change
  useEffect(() => {
    let cost = 0;

    if (timeOption === "hourly") {
      cost =
        data?.pricing?.hourlyRate * bags * hours +
        data?.pricing?.hourlyServiceCharge * 1;
    } else if (timeOption === "daily") {
      cost =
        data?.pricing?.dailyRate * bags * days +
        data?.pricing?.dailyServiceCharge * days; // Daily rate assumes 24 hours
    }

    setTotalPrice(cost); // Add service fee ($4 in this example)
  }, [bags, timeOption, days, hours]);

  const handleBagsChange = (value) => {
    setBags(value || 0);
  };

  const handleInsuranceChange = (e) => {
    setInsurance(e.target.checked);
  };

  const handleDropOffDateChange = (e) => {
    setDropOffDate(e.target.value);
  };

  const handlePickUpDateChange = (e) => {
    setPickUpDate(e.target.value);
  };

  const isStoreOpenOnDate = (storeData, selectedDate) => {
    const date = new Date(selectedDate);
    const dayOfWeek = date.toLocaleString("en-US", { weekday: "long" });

    const daySchedule = storeData.openingHours[dayOfWeek];
    if (!daySchedule || !daySchedule.open) {
      // Store is closed on this day
      return false;
    }

    // Store is open, check if it has any hours
    const hours = daySchedule.hours;
    return hours && hours.length > 0;
  };

  const handleSubmitForm = async () => {
    let isValid = true;

    if (!isStoreOpenOnDate(data, dropOffDate)) {
      AntNotification(
        "warning",
        "Store Closed",
        "Please note that store is closed on the selected date"
      );
      isValid = false; // Disable if open is false
    }
    if (!isStoreOpenOnDate(data, pickUpDate)) {
      AntNotification(
        "warning",
        "Store Closed",
        "Please note that store is closed on the selected date"
      );
      isValid = false; // Disable if open is false
    }

    const dropDate = new Date(dropOffDate);
    const pickDate = new Date(pickUpDate);
    const differenceInTime = pickDate - dropDate;

    if (differenceInTime / (1000 * 60 * 60 * 24) < 0) {
      AntNotification(
        "error",
        "Invalid Days",
        "pickup date should be greater than or equal to drop off date"
      );
    }

    if (timeOption === "hourly" && hours <= 0) {
      AntNotification(
        "error",
        "Invalid Hours",
        "Number of hours must be greater than 0 for hourly bookings."
      );
      isValid = false; // Disable if hours are 0
    }
    if (timeOption === "daily" && (days <= 0 || days == null)) {
      AntNotification(
        "error",
        "Invalid Days",
        "Number of days must be greater than 0 for daily bookings."
      );
      isValid = false; // Disable if days are 0
    }

    if (isValid) {
      setPaymentPopupVisible(true);
    }
  };

  if (!visible) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        {auth ? (
          !isPaymentPopupVisible ? (
            <>
              <div className="popup-header">
                <h2>{data.title}</h2>
                <button className="close-button" onClick={onClose}>
                  &times;
                </button>
              </div>
              <div className="popup-content">
                <p className="popup-subtitle">{data?.subtitle}</p>
                <div className="popup-rating">
                  {/*<Text>  {data.rating} <StarOutlined style={{ color: "#ffc107" }} /> ({data.reviews} reviews) </Text>*/}
                  <Text>
                    {" "}
                    {data.rating} <StarOutlined style={{ color: "#ffc107" }} />{" "}
                    ({data.reviews} reviews){" "}
                  </Text>
                </div>
                <div className="popup-tags">
                  {data?.tags?.map((tag, index) =>
                    tag.label === "Highly Rated" &&
                    data.rating <= 4.0 ? null : (
                      <Tag
                        key={index}
                        className="popup-tag"
                        color={tag.color}
                        icon={
                          tag.label === "Highly Rated" ? (
                            <StarFilled />
                          ) : tag.label === "Open Late" ? (
                            <ClockCircleFilled />
                          ) : null
                        }
                      >
                        {tag.label}
                      </Tag>
                    )
                  )}
                </div>
                <div>
                  <p>
                    <strong>Location:</strong> Lat: {data.location.lat}, Lng:{" "}
                    {data.location.lng}
                  </p>
                </div>

                <div className="booking-dates">
                  <div className="booking-dates-drop-off">
                    <label className="popup-label">Drop off</label>
                    <input
                      type="date"
                      className="popup-input"
                      value={dropOffDate || getTodayDate()}
                      min={getTodayDate()} // Set minimum date to today's date
                      onChange={handleDropOffDateChange}
                    />
                  </div>
                  <div className="booking-dates-pickup">
                    <label className="popup-label">Pick up</label>
                    <input
                      type="date"
                      className="popup-input"
                      value={pickUpDate || getTodayDate()}
                      min={dropOffDate || getTodayDate()} // Ensure pick-up date is not before drop-off date
                      onChange={handlePickUpDateChange}
                    />
                  </div>
                </div>

                <label className="popup-label">How many bags?</label>
                <input
                  type="number"
                  className="popup-input"
                  value={bags}
                  min={1}
                  onChange={(e) => handleBagsChange(parseInt(e.target.value))}
                />

                <div className="time-selection">
                  {data?.pricing?.isHourly ? (
                    <label>
                      <input
                        type="radio"
                        name="timeOption"
                        value="hourly"
                        checked={timeOption === "hourly"}
                        onChange={() => setTimeOption("hourly")}
                      />
                      Hourly
                    </label>
                  ) : null}
                  {data?.pricing?.isDaily ? (
                    <label>
                      <input
                        type="radio"
                        name="timeOption"
                        value="daily"
                        checked={timeOption === "daily"}
                        onChange={() => setTimeOption("daily")}
                      />
                      Daily
                    </label>
                  ) : null}
                </div>

                {timeOption === "daily" ? (
                  <label className="popup-label">
                    Number of days:
                    <input
                      type="number"
                      className="popup-input"
                      value={days}
                      min="1"
                      onChange={(e) => setDays(parseInt(e.target.value))}
                    />
                  </label>
                ) : (
                  <label className="popup-label">
                    Number of hours:
                    <input
                      type="number"
                      className="popup-input"
                      value={hours}
                      min="1"
                      onChange={(e) => setHours(parseInt(e.target.value))}
                    />
                  </label>
                )}

                <div className="summary-section">
                  <h3>Summary</h3>
                  {timeOption === "hourly" ? (
                    <>
                      <p>
                        Storage for {bags} bag(s) at £{data.pricing.hourlyRate}
                        /hour
                      </p>
                      <p>Service fee: £{data.pricing.hourlyServiceCharge}</p>
                    </>
                  ) : timeOption === "daily" ? (
                    <>
                      <p>
                        Storage for {bags} bag(s) at £{data.pricing.dailyRate}
                        /day
                      </p>
                      <p>Service fee: £{data.pricing.dailyServiceCharge}/day</p>
                    </>
                  ) : null}
                  {/*<div className="insurance-section">*/}
                  {/*  <input*/}
                  {/*    type="checkbox"*/}
                  {/*    checked={insurance}*/}
                  {/*    onChange={handleInsuranceChange}*/}
                  {/*    id="insurance"*/}
                  {/*  />*/}
                  {/*  <label htmlFor="insurance">Insurance (+$5.90)</label>*/}
                  {/*</div>*/}
                  {/*<p className="free-cancellation">Free cancellation: Included</p>*/}
                </div>

                <div className="due-now-section">
                  {/* <h3>Total Price: £{totalPrice.toFixed(2)}</h3> */}
                  <h3>Total Price: £{totalPrice}</h3>
                  <p>* Calculated based on selected options</p>
                </div>

                <button
                  onClick={handleSubmitForm}
                  className="confirm-button yellow-btn"
                >
                  Confirm Booking
                </button>
              </div>
            </>
          ) : (
            <div className="popup-overlay">
              <div className="popup-container">
                <div className="popup-header">
                  <h2>Payment Gateway</h2>
                  <button
                    className="close-button"
                    onClick={() => {
                      setPaymentPopupVisible(false);
                      onClose();
                    }}
                  >
                    &times;
                  </button>
                </div>
                <div className="popup-content">
                  <div id="checkout">
                    {options && (
                      <EmbeddedCheckoutProvider
                        stripe={stripePromise}
                        options={options}
                      >
                        <EmbeddedCheckout />
                      </EmbeddedCheckoutProvider>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="popup-header">
            <p className="popup-subtitle">Login Before Booking</p>
            <button className="close-button" onClick={onClose}>
              &times;
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default LuggagePopup;
