import { API_URLS } from "../configs/api.urls";
import { axiosPrivate } from "../configs/axios";

export const getCurrentUser = async () => {
  return await axiosPrivate({
    url: API_URLS.GET_ME_URL,
    method: "GET",
  });
};

export const updateCurrentUser = async (data) => {
  return await axiosPrivate({
    url: API_URLS.UPDATE_USER_URL,
    method: "PUT",
    data,
  });
};

export const deleteCurrentUser = async (data) => {
  return await axiosPrivate({
    url: API_URLS.DELETE_USER_URL,
    method: "DELETE",
    data,
  });
};

export const updateCurrentUserPassword = async (data) => {
  return await axiosPrivate({
    url: API_URLS.UPDATE_USER_PASSWORD_URL,
    method: "PUT",
    data,
  });
};
