/*
 * Copyright © 2023-2024 www.4bex.com
 *
 * Licensed under the www.4bex.com License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.4bex.com/license
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import { Layout } from "antd";
import "./Navbar.css";
import User from "./user/User";

import logo from "../../../assests/images/mapicon2.png";
import {Link} from "react-router-dom";

const { Header } = Layout;

const NavBar = () => {
  return (
    <Header
      className="header"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#ffffff",
        marginBottom: "10px",
        paddingRight: "5px",
      }}
    >
      <Link to="/">
      <div className="logo-area">
        <img src={logo} alt="crypto-luggage" />
        <h2>LUGGAGE STORAGE</h2>
      </div>
      </Link>

      <div className="user-area">
        <User />
      </div>
    </Header>
  );
};

export default NavBar;
